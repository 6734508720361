import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Tread = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.597 15.6017H15.5976V6.00367H16.5934C17.1933 6.00367 17.7812 5.89569 18.333 5.67974L20.8285 4.71994C21.4524 4.47999 21.7523 3.78413 21.5244 3.17226C21.2844 2.54839 20.5886 2.24846 19.9767 2.47641L17.4812 3.43621C17.2053 3.54418 16.9053 3.60417 16.6054 3.60417H8.41113C7.75127 3.60417 7.21138 4.14406 7.21138 4.80392C7.21138 5.46378 7.75127 6.00367 8.41113 6.00367H13.2101V15.6017H5.39976C3.74411 15.6017 2.40039 16.9454 2.40039 18.601C2.40039 20.2567 3.74411 21.6004 5.39976 21.6004H18.597C20.2526 21.6004 21.5964 20.2567 21.5964 18.601C21.5964 16.9454 20.2526 15.6017 18.597 15.6017ZM18.597 19.2009H5.39976C5.06383 19.2009 4.79989 18.937 4.79989 18.601C4.79989 18.2651 5.06383 18.0011 5.39976 18.0011H18.597C18.9329 18.0011 19.1969 18.2651 19.1969 18.601C19.1969 18.937 18.9329 19.2009 18.597 19.2009Z" fill={color}/>
    <path d="M8.4 9.59961H6V14.3996H8.4V9.59961Z" fill={color}/>
    <path d="M11.9996 9.59961H9.59961V14.3996H11.9996V9.59961Z" fill={color}/>
    <path d="M4.80039 9.59961C3.40839 10.1037 2.40039 11.4562 2.40039 13.0668V15.5996C3.02439 14.9357 3.86439 14.5053 4.80039 14.3578V9.59961Z" fill={color}/>
    <path d="M20.4004 10.7998V14.8035C20.8444 15.0054 21.2524 15.2634 21.6004 15.5998V13.2895C21.6004 12.2914 21.1324 11.4166 20.4004 10.7998Z" fill={color}/>
    <path d="M16.7998 9.59961V14.3404H18.5998C18.8038 14.3404 19.0078 14.3759 19.1998 14.3996V9.81294C18.8278 9.68257 18.4198 9.59961 17.9998 9.59961H16.7998Z" fill={color}/>
  </Svg>

);

export default Tread;
