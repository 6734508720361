import styled from 'styled-components';

import HyroxLogo from 'app/ui/components/atoms/hyrox-logo';
import PoweredByFiit from 'app/ui/components/atoms/powered-by-fiit-horizontal';

export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.extreme};
`;

export const LargeHyroxLogo = styled(HyroxLogo)`
  width: 8rem;
  height: auto;
`;

export const LargePoweredByFiit = styled(PoweredByFiit)`
  width: 9rem;
  height: auto;
`;
