import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  className?: string,
};

const Hide = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9948 19.793C7.43737 19.7628 3.24327 17.1344 1 12.9025L2.80993 11.793C4.67689 15.3388 8.18237 17.5451 11.9948 17.5738C15.8098 17.5445 19.3181 15.3389 21.1901 11.793L23 12.9025C20.7549 17.1378 16.556 19.7668 11.9948 19.793Z" fill="black"/>
  </Svg>
);



export default Hide;
