import React, { createContext, useContext, ReactNode } from 'react';

import { Config } from 'app/config';
import createAuthService from 'app/services/auth';
import createPasswordService from 'app/services/password';

const createServices = (config: Config) => ({
  auth: createAuthService({
    clientId: config.REACT_APP_AUTH_CLIENT_ID,
    clientSecret: config.REACT_APP_AUTH_CLIENT_SECRET,
    url: config.REACT_APP_AUTH_URL,
  }),
  password: createPasswordService({
    url: config.REACT_APP_PASSWORD_URL,
  }),
});

const context = createContext({} as ReturnType<typeof createServices>);
const { Provider } = context;

export const ServicesProvider = ({ children, config }: { children: ReactNode, config: Config }) => (
  <Provider value={createServices(config)}>
    { children }
  </Provider>
);

export default () => useContext(context);
