import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { semimedium } } }) => `
    width: ${semimedium};
    height: ${semimedium};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Filters = ({ className = '', color }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.2 4.8H5.6V3.2H0V1.6H5.6V0H7.2V1.6H16V3.2L8 3.2V1.6L7.2 1.6V4.8ZM16 8.8V7.2H13.5999V8.8L16 8.8ZM12.8 5.6H11.2V7.2H0V8.8H11.2V10.4H12.8V5.6ZM16 14.4H4V16H2.4V14.4H0V12.8H2.4V11.2H4V14.4L4.79998 14.4V12.8L16 12.8V14.4Z" fill={color} />
  </Svg>
);

export default Filters;
