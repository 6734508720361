import { IntlShape } from 'react-intl';

type GenerateBreadcrumbsProps = {
  intl: IntlShape,
  pathname: string,
  contentTitle: string,
};

export const generateBreadcrumbs = ({ intl, pathname, contentTitle, }: GenerateBreadcrumbsProps) => {
  const pathnames = pathname.split('/').filter(Boolean);
  
  return pathnames.map((path, index) => {
    const href = `/${pathnames.slice(0, index + 1).join('/')}`;
    const isLast = index === pathnames.length - 1;

    return {
      title: isLast ? contentTitle : intl.formatMessage({ id: `breadcrumb.${path}` }),
      href,
    };
  });
};
