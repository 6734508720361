import { OnboardActionTypes } from 'actions/onboard';
import createReducer from 'utils/create-reducer';

export type OnboardState = {
  gymType: boolean;
  gymEquipment: boolean;
  loaded: boolean;
};

export const initialOnboardState: OnboardState = {
  gymType: false,
  gymEquipment: false,
  loaded: false,
};

const handlers = {
  [OnboardActionTypes.GYM_TYPE]: (state: OnboardState): OnboardState => ({
    ...state,
    gymType: true,
  }),
  [OnboardActionTypes.GYM_EQUIPMENT]: (state: OnboardState): OnboardState => ({
    ...state,
    gymEquipment: true,
  }),
  [OnboardActionTypes.ONBOARD_STATUS_LOADED]: (state: OnboardState): OnboardState => ({
    ...state,
    loaded: true,
  }),
  [OnboardActionTypes.ONBOARD_STATUS_RESET]: (): OnboardState => initialOnboardState,
};

export const onboardReducer = createReducer<OnboardState>(handlers);
