import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Navbar from 'app/ui/components/atoms/navbar';

const Wrapper = styled.div`
  margin: 0 auto 5rem;

  // Add styles for different device sizes
  ${({ theme: { media } }) => {
    return `
        ${media.small(
          () => `
            padding: 0 2.1875rem;
            `,
        )}
        ${media.large(
          () => `
            max-width: 67.5rem;
          `,
        )}
        `;
  }}
`;

type GenericPageProps = {
  children: ReactNode,
};

const GenericPage: React.FC<GenericPageProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <Wrapper>
        {children}
      </Wrapper>
    </>
  );
};

export default GenericPage;
