import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import EmptyBookmarkIcon from 'app/ui/components/atoms/icons/bookmark/empty';
import FilledBookmarkIcon from 'app/ui/components/atoms/icons/bookmark/filled';
import TickIcon from 'app/ui/components/atoms/icons/tick';
import Button from 'app/ui/components/atoms/button';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const StyledEmptyBookmarkIcon = styled(EmptyBookmarkIcon)`
  height: 1rem;
`;

const StyledFilledBookmarkIcon = styled(FilledBookmarkIcon)`
  height: 1rem;
`;

type Props = {
  isSaved: boolean;
  isCompleted: boolean;
  saveAction: () => void;
  unSaveAction: () => void;
  completeAction: () => void;
  unCompleteAction: () => void;
  className?: string;
};

const WorkoutActions = ({
  isSaved,
  isCompleted,
  saveAction,
  unSaveAction,
  completeAction,
  unCompleteAction,
  className,
}: Props) => {
  const theme = useTheme();
  const intl = useIntl();

  const saveLabel = intl.formatMessage({ id: 'lesson.buttons.save' });
  const savedLabel = intl.formatMessage({ id: 'lesson.buttons.saved' });
  const completeLabel = intl.formatMessage({ id: 'lesson.buttons.complete' });
  const completedLabel = intl.formatMessage({ id: 'lesson.buttons.completed' });

  return (
    <Actions className={className}>
      {isSaved ? (
        <Button
          onClick={unSaveAction}
          label={savedLabel}
          iconPosition="left"
          icon={<StyledFilledBookmarkIcon color={theme.colors.white} />}
          type="curved"
        />
      ) : (
        <Button
          onClick={saveAction}
          label={saveLabel}
          iconPosition="left"
          icon={<StyledEmptyBookmarkIcon color={theme.colors.white} />}
          type="curved-grey"
        />
      )}
      {isCompleted ? (
        <Button
          onClick={unCompleteAction}
          label={completedLabel}
          iconPosition="left"
          icon={<TickIcon color={theme.colors.white} />}
          type="curved"
        />
      ) : (
        <Button onClick={completeAction} label={completeLabel} type="curved-grey" />
      )}
    </Actions>
  );
};

export default WorkoutActions;
