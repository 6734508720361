import React from 'react';
import Slider from 'rc-slider';
import styled, { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';

type Props = {
  max: number,
  label?: string,
  min?: number,
  defaultValue?: number,
  value?: number | null,
  step?: number,
  markLabels?: MarkLabels,
  onChange?: any,
};

type GetSliderMarksProps = {
  min: number,
  max: number,
  markLabels: MarkLabels,
}

type MarkLabels = {
  min: string,
  mid: string,
  max: string,
};

const MarkText = styled.span`
  line-height: 50px;
  color: white;
`;

const SliderLabel = styled.div`
  margin-bottom: 1rem;
`;

const SliderContainer = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const StyledSlider = styled(Slider)`
  .rc-slider-mark-text {
    width: 3rem;
  }
`;

const getSliderMarks = ({ min, max, markLabels }: GetSliderMarksProps) => {
  const mid = Math.floor((min + max) / 2);

  return {
    [min]: <MarkText>{markLabels.min}</MarkText>,
    [mid]: <MarkText>{markLabels.mid}</MarkText>,
    [max]: <MarkText>{markLabels.max}</MarkText>,
  };
};

const NumberSlider: React.FC<Props> = ({
  min = 1,
  max,
  step = 1,
  defaultValue = 1,
  value,
  label,
  markLabels,
  onChange,
}: Props) => {
  const theme = useTheme();

  return (
    <>
      {label && (
        <SliderLabel>
          <Typography variant="body2">{label}</Typography>
        </SliderLabel>
      )}
      <SliderContainer>
        <StyledSlider
          value={value || defaultValue}
          min={min}
          max={max}
          defaultValue={defaultValue}
          step={step}
          marks={markLabels && getSliderMarks({ min, max, markLabels })}
          onChange={(value) => onChange(value)}
          styles={{
            track: {
              backgroundColor: theme.colors.focoltone,
              height: theme.spacing.semimedium,
              left: '-5px',
            },
            handle: {
              borderColor: theme.colors.focoltone,
              height: theme.spacing.xxlarge,
              width: theme.spacing.xxlarge,
              backgroundColor: theme.colors.focoltone,
              opacity: 1,
            },
            rail: {
              backgroundColor: theme.colors.veryGray,
              height: theme.spacing.semimedium,
              left: '5px',
            },
          }}
          dotStyle={{
            borderColor: theme.colors.lightGray,
            backgroundColor: theme.colors.lightGray,
            top: '1.5px',
            height: theme.spacing.smaller,
            width: theme.spacing.smaller,
          }}
          activeDotStyle={{
            borderColor: theme.colors.focoltone,
            backgroundColor: theme.colors.focoltone,
            top: '1.5px',
            height: theme.spacing.smaller,
            width: theme.spacing.smaller,
          }}
          dots
        />
      </SliderContainer>
    </>
  );
};

export default NumberSlider;
