import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAppState } from 'state';

import { routes } from 'app/routes/constants';

export const SigninRoute = () => {
  const navigate = useNavigate();
  const signedIn = useAppState(({ auth }) => auth.signedIn);
  if (signedIn) {
    navigate(routes.HOME);
  }
  return <Outlet />;
};
