import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';
import { theme } from 'app/providers/theme';

import useConfig from 'app/config';

const InnerContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: ${({ theme }) => `0.125rem solid ${theme.colors.veryGray}`};
  gap: 1rem;
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing.jumbo};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        flex-direction: row-reverse;
        width: 60%;
      `,
    )}
  `}
`;

const WiderButton = styled(Button)`
  width: 80%;

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        width: 50%;
      `,
    )}
  `}
`;

const handleContactUsClick = () => {
  const emailSubject = encodeURIComponent('[HYROX FAQ] Support Request');
  window.location.href = `mailto:support+hyrox@fiit.tv?subject=${emailSubject}`;
};

const handleShareYourFeedbackClick = (feedbackUrl: string) => (
  window.open(feedbackUrl, '_blank')
);

const GetInTouchSection: React.FC = () => {
  const intl = useIntl();
  const { config } = useConfig();

  const contactHeader = intl.formatMessage({ id: 'contact.header' });
  const contactUsLabel = intl.formatMessage({ id: 'contact.buttons.contact' });
  const feedbackLabel = intl.formatMessage({ id: 'contact.buttons.feedback' });

  return (
    <InnerContentWrapper>
      <Typography variant="body1" color={theme.colors.lightGray}>
        {contactHeader}
      </Typography>
      <ButtonWrapper>
        <WiderButton
          label={contactUsLabel}
          type="primary"
          onClick={handleContactUsClick}
        />
        <WiderButton
          label={feedbackLabel}
          type="secondary"
          onClick={() => handleShareYourFeedbackClick(config.REACT_APP_FEEDBACK_URL)}
        />
      </ButtonWrapper>
    </InnerContentWrapper>
  );
};

export default GetInTouchSection;
