import React, { createContext as createReactContext, useContext, useReducer, Dispatch, ReactNode } from 'react';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';

import { AuthState, authReducer, initialAuthState } from 'state/auth';
import { OnboardState, initialOnboardState, onboardReducer } from 'state/onboard';
import { initialLanguageState, languageReducer, LanguageState } from 'state/language';

import { AllActions } from 'actions';

type State = {
  auth: AuthState,
  onboard: OnboardState,
  language: LanguageState,
};

const combinedReducer = ({ auth, onboard, language }: State, action: AllActions) => ({
  auth: authReducer(auth, action),
  onboard: onboardReducer(onboard, action),
  language: languageReducer(language, action)
});

const initialState = {
  auth: initialAuthState,
  onboard: initialOnboardState,
  language: initialLanguageState,
};

export const StateContext = createSelectableContext(initialState);
type Selector<T> = (state: State) => T;
export function useAppState<T>(selector: Selector<T>): T {
  return useContextSelector(StateContext, selector);
}

const initialDispatch: Dispatch<AllActions> = () => null;
export const DispatchContext = createReactContext(initialDispatch);
export const useDispatch = () => useContext(DispatchContext);

export const StateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(combinedReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};
