import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'app/ui/components/atoms/typography';

export type ResourceCardProps = {
  imageSrcSet: string,
  title: string,
  paragraph?: string | null,
  imagePosition?: 'left' | 'right',
  to: string,
};

const Container = styled.div<{ imagePosition: ResourceCardProps['imagePosition'] }>`
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 0.5rem;
  min-height: 0;
  flex-direction: column;
  ${({ imagePosition, theme: { media } }) => `
      ${media.medium(
        () => `
          min-height: 12.5rem;
          flex-direction: ${imagePosition === 'left' ? 'row' : 'row-reverse'};
      `,
      )}
`};
`;

const ImageWrapper = styled.div<{ isHover: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  flex-grow: 1;
  aspect-ratio: 2.66;
  min-height: 8.125rem;
  ${({ isHover }) => isHover && `
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background:
        linear-gradient(180deg, rgba(0, 0, 0, 0) 63.23%, rgba(0, 0, 0, 0.3) 100%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }
  `}

  ${({ theme: { media } }) => {
    return `
    ${media.medium(
      () => `
        width: 50%;
        aspect-ratio: 2;
      `,
    )}

      ${media.large(
        () => `
          aspect-ratio: 3;
        `,
      )}

      `;
  }};
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const ContentContainer = styled.div<{ isHover: boolean }>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme: { colors, spacing, media }, isHover }) => {
    return `
      padding: ${spacing.jumbo};
      background-color: ${isHover ? colors.focoltone : colors.veryGray};
      gap: ${spacing.xsmall};

      ${media.medium(
        () => `
        width: 50%;
      `,
      )}
      `;
  }};
`;

const ResourceCard: React.FC<ResourceCardProps> = ({ imageSrcSet, title, paragraph, imagePosition = 'left', to }) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);

  return (
    <Link to={to}>
      <Container
        imagePosition={imagePosition}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <ImageWrapper isHover={isHover}>
          <Image loading="lazy" srcSet={imageSrcSet} />
        </ImageWrapper>
        <ContentContainer isHover={isHover}>
          <Typography variant="h4" color={isHover ? theme.colors.black : theme.colors.white}>
            {title}
          </Typography>
          {paragraph && (
            <Typography variant="body2" color={isHover ? theme.colors.veryGray : theme.colors.lightGray}>
              {paragraph}
            </Typography>
          )}
        </ContentContainer>
      </Container>
    </Link>
  );
};

export default ResourceCard;
