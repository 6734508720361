import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  className?: string,
};

const PartnerDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5846_7121)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM7.625 10.25C9.07475 10.25 10.25 9.07475 10.25 7.625C10.25 6.17525 9.07475 5 7.625 5C6.17525 5 5 6.17525 5 7.625C5 9.07475 6.17525 10.25 7.625 10.25ZM4.125 14.625C4.125 12.692 5.692 11.125 7.625 11.125C9.558 11.125 11.125 12.692 11.125 14.625V18C11.125 18.5523 10.6773 19 10.125 19H5.125C4.57272 19 4.125 18.5523 4.125 18V14.625ZM12.875 14.625C12.875 12.692 14.442 11.125 16.375 11.125C18.308 11.125 19.875 12.692 19.875 14.625V18C19.875 18.5523 19.4273 19 18.875 19H13.875C13.3227 19 12.875 18.5523 12.875 18V14.625ZM19 7.625C19 9.07475 17.8247 10.25 16.375 10.25C14.9253 10.25 13.75 9.07475 13.75 7.625C13.75 6.17525 14.9253 5 16.375 5C17.8247 5 19 6.17525 19 7.625Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_5846_7121">
        <rect width="24" height="24" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
);

export default PartnerDark;
