import { Navigate } from 'react-router-dom';
import { routes } from 'app/routes/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import { useAppState, useDispatch } from 'state';

import EntryLayout from 'app/ui/components/layouts/entry';

import { PillFieldSet, Pills } from 'app/ui/components/molecules/pill-field-set';
import Loading from 'app/ui/components/molecules/loading';
import Error from 'app/ui/components/molecules/error';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';
import { Steps } from 'app/ui/components/atoms/steps';

import EQUIPMENTS from 'app/pages/onboarding-equipment/equipments.gql';
import PATCH_GYM_EQUIPMENTS from 'app/pages/onboarding-equipment/patch-gym-equipments.gql';

import {
  EquipmentsQuery,
  EquipmentsQueryVariables,
  PatchGymEquipmentsMutation,
  PatchGymEquipmentsMutationVariables,
} from 'app/types/graphql';
import { onboardGymEquipment } from 'actions/onboard';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  button {
    width: 100%;
    justify-content: center;
  }
`;

type OnBoardingPageProps = {
  userId: number;
};

const OnboardingPage = ({ userId }: OnBoardingPageProps) => {
  const dispatch = useDispatch();
  const { gymType, gymEquipment, loaded: onboardStatusLoaded } = useAppState(({ onboard }) => onboard);
  const [currentGymId, setCurrentGymId] = useState(0);
  const [canUserManageGym, setCanUserManageGym] = useState(false);
  const [pills, setPills] = useState<Pills>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setError] = useState<ApolloError>();
  
  const intl = useIntl();

  const { data, loading, error } = useQuery<EquipmentsQuery, EquipmentsQueryVariables>(EQUIPMENTS, {
    variables: { id: userId! },
  });

  const [patchGymById] = useMutation<PatchGymEquipmentsMutation, PatchGymEquipmentsMutationVariables>(
    PATCH_GYM_EQUIPMENTS,
    {
      onCompleted: () => {
        dispatch(onboardGymEquipment());
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        setError(error);
      },
    },
  );

  const pillsData = useMemo(
    () =>
      data?.allEquipment?.map((equipment) => ({
        id: equipment.id,
        name: equipment.name,
        isChecked: false,
      })) ?? [],
    [data],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;
      setPills((currentPills) =>
        currentPills.map((pill) => {
          if (pill.id == value) {
            return { ...pill, isChecked: !pill.isChecked };
          }
          return pill;
        }),
      );
    },
    [setPills],
  );

  const handleSubmit = useCallback(async () => {
    const selectedPills = pills.filter((pill) => pill.isChecked);

    try {
      setLoading(true);
      await patchGymById({
        variables: {
          id: Number(currentGymId),
          input: {
            equipment: selectedPills.map((pill) => ({ id: Number(pill.id) })),
          },
        },
      });
    } catch (error) {
      console.error('Error saving equipment:', error);
    }
  }, [pills]);

  useEffect(() => {
    setPills(pillsData);
    setLoading(loading);
    setError(error);

    if (data?.userById?.gym?.id) {
      setCurrentGymId(data?.userById?.gym?.id);
    }

    if (data?.userById?.permissions?.canManageGym) {
      setCanUserManageGym(data?.userById?.permissions?.canManageGym);
    }
  }, [pillsData, loading, error]);

  if (isLoading) {
    return <Loading />;
  }

  if (hasError || !pills) {
    return <Error />;
  }

  if ((onboardStatusLoaded && gymType && gymEquipment) || !canUserManageGym) {
    return <Navigate to={routes.HOME} />;
  }

  if (onboardStatusLoaded && !gymType) {
    return <Navigate to={routes.ONBOARDING_GYM_TYPE} />;
  }

  if (pills.length > 0) {
    const question = intl.formatMessage({ id: 'onboardingEquipment.questions.equipmentAccess' });
    const actionLabel = intl.formatMessage({ id: 'onboardingEquipment.actions.continue' });

    return (
      <EntryLayout $showBrandImage={false}>
        <Steps totalSteps={2} currentStep={2} />
        <Typography variant="h4">{question}</Typography>
        <PillFieldSet label="" pills={pills} onChange={handleChange} />
        <ButtonWrapper>
          <Button
            type="primary"
            label={actionLabel}
            onClick={handleSubmit}
            state={pills.filter((pill) => pill.isChecked).length === 0 ? 'disabled' : 'default'}
          />
        </ButtonWrapper>
      </EntryLayout>
    );
  }

  return null;
};

const Onboarding: React.FC = () => {
  const userId = useAppState(({ auth }) => auth.userId);

  if (!userId) {
    return <Error />;
  }

  return <OnboardingPage userId={userId} />;
};

export default Onboarding;
