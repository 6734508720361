import React from 'react';

import GenericPage from 'app/ui/components/layouts/generic-page';
import WeeklyWorkoutListWrapper from 'app/ui/components/molecules/weekly-workout-list-wrapper';

export const WeeklyWorkouts: React.FC = () => (
  <GenericPage>
    <WeeklyWorkoutListWrapper />
  </GenericPage>
);

export default WeeklyWorkouts;
