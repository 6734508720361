import React from 'react';
import styled from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

type ResourceHeaderProps = {
  title: string;
  content?: string | null;
  imageUrl?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  margin-left: -10%;
  justify-content: center;
  align-items: flex-end;

  ${({ theme: { media } }) =>
    `${media.medium(
      () => `
        margin-left: -2.1875rem;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
      `,
    )}`};

    ${({ theme: { media } }) =>
      `${media.large(
        () => `
          margin-left: -13.45rem;
        `,
      )}`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 0 2.1875rem;

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        bottom: unset;
        max-width: 40%;
        padding: 0;
        left: 2.1875rem;
      `,
    )}

    ${media.large(
      () => `
        left: 13.1875rem;
        max-width: 30%;
      `,
    )}
  `};
`;

const TitleWrapper = styled.div`
  ${({ theme: { media, spacing } }) => `
    margin: 0 0 ${spacing.jumbo} 0;

    ${media.medium(() => 'margin-bottom: 0.6rem;')}
  `};

  h1 {
    text-transform: uppercase;
    line-height: 1em;
  }
`;

const TextContentWrapper = styled.div`
  ${({ theme: { media, spacing } }) => `
    margin: 0 0 ${spacing.jumbo} 0;

    ${media.medium(() => 'margin: 0.6rem 0;')}
  `};
`;

const StyledImageWrapper = styled.div<{ url: string }>`
  width: 100%;
  height: 25rem;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(${({ url }) => url});

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, black 0%, transparent 70%);
  }

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
      width: 80%;
    `)}
    ${media.large(
      () => `
      height: 27.5rem;
    `)}
    `};
`;

const ResourceHeader: React.FC<ResourceHeaderProps> = ({ title, content, imageUrl = '' }) => (
  <Wrapper>
    <TextWrapper>
      <TitleWrapper>
        <Typography variant="h1">{title}</Typography>
      </TitleWrapper>
      <TextContentWrapper>{content && <Typography variant="body1">{content}</Typography>}</TextContentWrapper>
    </TextWrapper>
    <StyledImageWrapper url={imageUrl} />
  </Wrapper>
);

export default ResourceHeader;
