import { theme } from 'app/providers/theme';
import React from 'react';

export type Props = {
  color?: string,
  className?: string,
};

const HyroxLogo: React.FC<Props> = ({ color = theme.colors.white, className }) => {
  return (
<svg className={className} id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.43 145.16">
  <g id="Ebene_1-2" data-name="Ebene 1">
    <g>
      <polygon fill={color} points="38.56 1.43 38.56 29.78 11.59 29.78 11.59 1.43 .43 1.43 .43 68.25 11.59 68.25 11.59 39.81 38.56 39.81 38.56 68.25 49.71 68.25 49.71 1.43 38.56 1.43"/>
      <polygon fill={color} points="133.3 41.81 111.27 5.25 111.27 1.43 122.24 1.43 138.98 31.02 156.27 1.43 166.5 1.43 166.5 5.25 144.46 41.33 144.46 68.25 133.3 68.25 133.3 41.81"/>
      <path fill={color} d="M226.01,1.43h27.99c12.83,0,19.62,7.73,19.62,20.52,0,8.69-3.72,15.94-11.44,19.29l12.18,23.2v3.82h-10.97l-13.39-25.68h-12.84v25.68h-11.16V1.43ZM251.95,32.84c6.51,0,10.32-3.24,10.32-10.69s-3.81-10.69-10.32-10.69h-14.78v21.38h14.78Z"/>
      <path fill={color} d="M332.47,45.63v-21.57c0-15.18,9.95-24.05,25.57-24.05s25.57,8.88,25.57,24.05v21.57c0,15.18-9.95,24.06-25.57,24.06s-25.57-8.88-25.57-24.06M372.46,46.2v-22.72c0-7.16-4.74-12.98-14.42-12.98s-14.41,5.82-14.41,12.98v22.72c0,7.16,4.74,12.98,14.41,12.98s14.42-5.82,14.42-12.98"/>
      <polygon fill={color} points="437.73 68.25 448.42 68.25 464.79 42.86 481.06 68.25 492.41 68.25 492.41 64.43 471.86 33.6 490.83 5.25 490.83 1.43 480.13 1.43 465.07 24.72 450.65 1.43 439.31 1.43 439.31 5.25 458.09 33.7 437.73 64.43 437.73 68.25"/>
      <g>
        <path fill={color} d="M2.79,144.68H0v-22.43h9.39c1.92,0,3.43.61,4.54,1.83,1.1,1.22,1.65,2.86,1.65,4.94s-.55,3.71-1.65,4.92-2.61,1.81-4.54,1.81H2.79v8.94ZM2.79,124.71v8.56h6.12c1.17,0,2.1-.38,2.79-1.14.68-.76,1.03-1.8,1.03-3.12s-.34-2.4-1.03-3.16-1.61-1.14-2.79-1.14H2.79Z"/>
        <path fill={color} d="M47.95,144.68h-14.01v-22.43h13.97v2.47h-11.18v7.44h9.68v2.47h-9.68v7.59h11.22v2.47Z"/>
        <path fill={color} d="M69.29,144.68h-2.79v-22.43h9.39c2.05,0,3.65.6,4.79,1.79,1.14,1.2,1.72,2.85,1.72,4.97,0,3.29-1.43,5.44-4.3,6.44l4.65,8.3v.93h-2.76l-4.97-8.88h-5.74v8.88ZM69.29,124.71v8.62h6.12c1.32,0,2.34-.37,3.06-1.11.72-.74,1.07-1.81,1.07-3.22,0-2.86-1.38-4.29-4.13-4.29h-6.12Z"/>
        <path fill={color} d="M104.03,144.68h-2.82v-22.43h14.13v2.47h-11.31v7.6h9.77v2.47h-9.77v9.9Z"/>
        <path fill={color} d="M132.91,137.12v-7.31c0-2.5.77-4.47,2.32-5.9,1.55-1.43,3.65-2.15,6.3-2.15s4.75.72,6.3,2.15c1.55,1.43,2.32,3.4,2.32,5.9v7.31c0,2.5-.77,4.47-2.32,5.9-1.55,1.43-3.65,2.15-6.3,2.15s-4.75-.72-6.3-2.15c-1.55-1.43-2.32-3.4-2.32-5.9ZM141.53,142.6c1.82,0,3.24-.49,4.28-1.46,1.04-.97,1.55-2.27,1.55-3.89v-7.56c0-1.62-.52-2.92-1.55-3.89-1.04-.97-2.46-1.46-4.28-1.46s-3.27.49-4.29,1.46-1.54,2.27-1.54,3.89v7.56c0,1.62.51,2.92,1.54,3.89s2.46,1.46,4.29,1.46Z"/>
        <path fill={color} d="M172.1,144.68h-2.79v-22.43h9.39c2.05,0,3.65.6,4.79,1.79,1.14,1.2,1.72,2.85,1.72,4.97,0,3.29-1.43,5.44-4.3,6.44l4.65,8.3v.93h-2.76l-4.97-8.88h-5.74v8.88ZM172.1,124.71v8.62h6.12c1.32,0,2.34-.37,3.06-1.11.72-.74,1.07-1.81,1.07-3.22,0-2.86-1.38-4.29-4.13-4.29h-6.12Z"/>
        <path fill={color} d="M206.72,144.68h-2.69v-22.43h2.69l7.24,11.38,7.24-11.38h2.6v22.43h-2.69v-17.5l-6.28,9.87h-1.83l-6.28-9.84v17.47Z"/>
        <path fill={color} d="M243.93,144.68h-2.56v-.93l8.37-21.5h2.79l8.4,21.5v.93h-2.76l-2.24-5.96h-9.68l-2.31,5.96ZM251.07,125.96l-4.01,10.38h7.98l-3.97-10.38Z"/>
        <path fill={color} d="M281.26,144.68h-2.79v-22.43h2.56l11.15,17.27v-17.27h2.79v22.43h-2.56l-11.15-17.24v17.24Z"/>
        <path fill={color} d="M322.73,145.16c-2.71,0-4.82-.71-6.33-2.13s-2.26-3.4-2.26-5.95v-7.24c0-2.54.75-4.52,2.26-5.94,1.51-1.42,3.62-2.13,6.33-2.13,2.33,0,4.27.65,5.83,1.96,1.56,1.3,2.34,3.19,2.34,5.67v.32h-2.79v-.32c0-1.65-.54-2.9-1.63-3.75-1.07-.88-2.33-1.31-3.78-1.31-1.86,0-3.28.46-4.28,1.39-.99.93-1.49,2.24-1.49,3.93v7.63c0,1.69.5,3,1.49,3.93s2.42,1.39,4.28,1.39c1.45,0,2.71-.44,3.78-1.31,1.09-.85,1.63-2.1,1.63-3.75v-.32h2.79v.32c0,2.48-.78,4.37-2.34,5.67-1.56,1.3-3.5,1.96-5.83,1.96Z"/>
        <path fill={color} d="M363.76,144.68h-14.01v-22.43h13.97v2.47h-11.18v7.44h9.68v2.47h-9.68v7.59h11.22v2.47Z"/>
        <path fill={color} d="M406.93,144.68h-2.79v-22.43h2.79v9.94h10.93v-9.94h2.79v22.43h-2.79v-10.03h-10.93v10.03Z"/>
        <path fill={color} d="M448.4,145.16c-2.5,0-4.5-.68-5.99-2.05-1.52-1.39-2.28-3.3-2.28-5.74v-15.13h2.79v15.22c0,1.56.49,2.8,1.46,3.73.97.93,2.31,1.39,4.02,1.39s3.04-.45,4.01-1.36c.96-.91,1.44-2.16,1.44-3.77v-15.22h2.79v15.13c0,2.44-.76,4.35-2.28,5.74-1.5,1.37-3.48,2.05-5.96,2.05Z"/>
        <path fill={color} d="M486.34,144.68h-10.22v-22.43h9.42c1.92,0,3.41.51,4.45,1.52,1.05,1.02,1.57,2.47,1.57,4.37,0,2.41-.97,4.04-2.92,4.87,2.52.73,3.78,2.57,3.78,5.54,0,1.97-.53,3.48-1.6,4.54-1.07,1.06-2.56,1.59-4.49,1.59ZM478.91,124.71v7.44h6.22c1.17,0,2.08-.33,2.71-.98.63-.65.95-1.56.95-2.74s-.32-2.12-.95-2.76c-.63-.64-1.55-.96-2.77-.96h-6.15ZM478.91,134.52v7.69h6.95c1.22,0,2.15-.33,2.8-.99.65-.66.98-1.6.98-2.82s-.33-2.19-.98-2.87c-.65-.67-1.59-1.01-2.8-1.01h-6.95Z"/>
      </g>
    </g>
  </g>
</svg>
  );
};

export default HyroxLogo;
