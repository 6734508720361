import React from 'react';
import styled, { keyframes } from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  p {
    text-transform: uppercase;
  }
`;

// Keyframes for animations
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const stretch = keyframes`
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 75, 150;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dashoffset: -100;
  }
`;

// Styled components
const Loader = styled.svg`
  --uib-size: 2.5rem;
  --uib-color: ${({ theme }) => theme.colors.focoltone};
  --uib-speed: 2s;
  --uib-bg-opacity: 0.05;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  animation: ${rotate} var(--uib-speed) linear infinite;
  will-change: transform;
  overflow: visible;
`;

const Car = styled.circle`
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${stretch} calc(var(--uib-speed) * 0.75) ease-in-out infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
`;

const Track = styled.circle`
  fill: none;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
`;

const Loading: React.FC = () => {
  return (
    <LoaderWrapper>
      <Loader viewBox="0 0 40 40">
        <Track cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="0.25rem" fill="none" />
        <Car cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="0.25rem" fill="none" />
      </Loader>
      <Typography variant="body1">Loading...</Typography>
    </LoaderWrapper>
  );
};

export default Loading;
