import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import Button from 'app/ui/components/atoms/button';
import FormFieldSet from 'app/ui/components/molecules/form-field-set';

import { UserFormData, GymFormData } from 'app/pages/profile';
import { EditPageButtonWrapper } from 'app/ui/components/molecules/profile-button-wrapper';
import { Pills } from 'app/ui/components/molecules/pill-field-set';

import { Equipment, GymType } from 'app/types/graphql';

interface renderEditProfileArgs {
  handleCancelButtonClick: () => void;
  handleSaveProfileButtonClick: () => Promise<void>;
  handleUserDetailsChange: (object: UserFormData) => void;
  handleGymDetailsChange: (object: GymFormData) => void;
  userDetails: UserFormData;
  gymDetails: GymFormData;
  allEquipment: Equipment[];
  allGymTypes: GymType[];
  errorMessage: string;
  canEditGym: boolean;
}

const EditProfileWrapper = styled.div`
  width: 100%;
  max-width: 33.125rem;
  margin-bottom: 4rem;

  input[type='text'] {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  input[type='password'] {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  label {
    width: fit-content;
  }
`;

const FullNameFormLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ theme: { media } }) => `${media.medium(() => 'flex-direction: row;')}`};

  div {
    flex: 1;
  }
`;

const EditProfile: React.FC<renderEditProfileArgs> = ({
  handleCancelButtonClick,
  handleSaveProfileButtonClick,
  userDetails,
  gymDetails,
  handleUserDetailsChange,
  handleGymDetailsChange,
  allEquipment,
  allGymTypes,
  errorMessage,
  canEditGym,
}) => {
  const intl = useIntl();
  const theme = useTheme();

  const _pills: Pills = allEquipment.map((equipment) => ({
    id: equipment.id,
    name: equipment.name,
    isChecked: !!gymDetails.equipment.find((userEquipment: Equipment) => userEquipment.id === equipment.id),
  }));

  const handleSaveClick = async () => {
    try {
      await handleSaveProfileButtonClick();
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const nameLabel = intl.formatMessage({ id: 'profile.labels.firstName' });
  const surnameLabel = intl.formatMessage({ id: 'profile.labels.surname' });

  const gymTypeLabel = intl.formatMessage({ id: 'profile.labels.gymType' });
  const gymTypeMessage = intl.formatMessage({ id: 'profile.messages.gymType' });

  const changePasswordLabel = intl.formatMessage({ id: 'profile.labels.passwords.change' });
  const confirmPasswordLabel = intl.formatMessage({ id: 'profile.labels.passwords.confirm' });
  const changePasswordMessage = intl.formatMessage({ id: 'profile.messages.passwords.change' });
  const confirmPasswordMessage = intl.formatMessage({ id: 'profile.messages.passwords.confirm' });

  const cancelLabel = intl.formatMessage({ id: 'profile.buttons.cancel' });
  const saveLabel = intl.formatMessage({ id: 'profile.buttons.save' });

  return (
    <EditProfileWrapper>
      <FullNameFormLine>
        <FormFieldSet
          label={nameLabel}
          onChange={(value) => handleUserDetailsChange({ ...userDetails, firstName: value })}
          value={userDetails.firstName}
          type="text"
        />
        <FormFieldSet
          label={surnameLabel}
          onChange={(value) => handleUserDetailsChange({ ...userDetails, lastName: value })}
          value={userDetails.lastName}
          type="text"
        />
      </FullNameFormLine>
      <FormFieldSet
        label={gymTypeLabel}
        labelColor={theme.colors.veryGray}
        onChange={({ id, name }) => handleGymDetailsChange({ ...gymDetails, type: { id: Number(id), name } })}
        value={gymDetails.type?.name}
        type="dropdown"
        options={allGymTypes.map(({ id, name }) => ({ id: id.toString(), name }))}
        noValueText={gymTypeMessage}
        disabled={!canEditGym}
      />
      <FormFieldSet
        label={changePasswordLabel}
        value={userDetails.password}
        placeholder={changePasswordMessage}
        onChange={(value) => handleUserDetailsChange({ ...userDetails, password: value })}
        type="password"
      />
      <FormFieldSet
        label={confirmPasswordLabel}
        placeholder={confirmPasswordMessage}
        value={userDetails.confirmedPassword}
        onChange={(value) => handleUserDetailsChange({ ...userDetails, confirmedPassword: value })}
        type="password"
        errorText={errorMessage}
      />
      <EditPageButtonWrapper>
        <Button label={cancelLabel} type="secondary" onClick={handleCancelButtonClick} />
        <Button label={saveLabel} type="primary" onClick={handleSaveClick} />
      </EditPageButtonWrapper>
    </EditProfileWrapper>
  );
};

export default EditProfile;
