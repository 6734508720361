import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Dumbbell = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3047 17C19.3047 17.2762 19.0808 17.5 18.8047 17.5H15.9898C15.7137 17.5 15.4898 17.2762 15.4898 17V13.7687L8.85487 13.7687V17.0001C8.85487 17.2762 8.63101 17.5001 8.35487 17.5001H5.54003C5.26389 17.5001 5.04003 17.2762 5.04003 17.0001V7.99223C5.04003 7.71609 5.26389 7.49224 5.54003 7.49224H8.35487C8.63101 7.49224 8.85487 7.71609 8.85487 7.99224V11.2255L15.4898 11.2255V7.99219C15.4898 7.71604 15.7137 7.49219 15.9898 7.49219L18.8047 7.49219C19.0808 7.49219 19.3047 7.71605 19.3047 7.99219V17ZM2.5 9.95237C2.22386 9.95237 2 10.1762 2 10.4524V14.5388C2 14.815 2.22386 15.0388 2.5 15.0388H4.54323V9.95237H2.5ZM22.3447 14.5392C22.3447 14.8154 22.1209 15.0392 21.8447 15.0392H19.8015V9.9528L21.8447 9.9528C22.1209 9.9528 22.3447 10.1767 22.3447 10.4528V14.5392Z" fill={color}/>
  </Svg>
);

export default Dumbbell;
