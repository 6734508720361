const toCamelCase = (str: string): string => {
  return str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const camelify = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => camelify(item));
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => ({
      ...acc,
      [toCamelCase(key)]: camelify(obj[key]),
    }), {});
  }

  return obj;
};

export default camelify;
