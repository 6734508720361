import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';

export type Props = {
  icon?: ReactNode,
  heading?: string,
  subheading?: string,
  buttonLabel?: string,
  className?: string,
  onAction?: () => void,
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme: { spacing } }) => `
      padding: ${spacing.xsmall};
  `}
`;

const Heading = styled(Typography)`
  ${({ theme: { spacing } }) => `
      margin-top: ${spacing.xsmall};
  `}
`;

const Subheading = styled(Typography)`
  ${({ theme: { spacing } }) => `
      margin-top: ${spacing.xsmall};
  `}
`;

const Icon = styled.div`
  ${({ theme: { spacing } }) => `
      padding: ${spacing.xsmall} 0;
  `}
`;

const EmptyState = ({
  icon,
  heading,
  subheading,
  buttonLabel,
  className,
  onAction,
}: Props) => (
  <Wrapper className={className}>
    <Icon>{icon}</Icon>
    <Heading variant="caption">{ heading }</Heading>
    <Subheading variant="caption">{ subheading }</Subheading>
    {
      buttonLabel && (
        <Button
          onClick={onAction}
          label={buttonLabel}
        />
      )
    }
  </Wrapper>
);

export default EmptyState;
