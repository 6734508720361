import React, { useState, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { ForwardIcon, RewindIcon, PlayIcon, PauseIcon, PlayOnThumbnailIcon } from 'app/ui/components/atoms/controls';
import Typography from 'app/ui/components/atoms/typography';

const VideoContainer = styled.div`
  position: relative;
  &:hover button {
    opacity: 1;
  }
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  transition: opacity 0.3s ease;
  &:focus {
    outline: none;
  }
`;

const PlayButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.66rem;
`;

const ControlWrapper = styled.div<{ showPointer: boolean }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ showPointer }) => (showPointer ? 'cursor: pointer' : null)};
`;

const Controls = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`;

const RewindButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.66rem;
`;

const ForwardButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.66rem;
`;

const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white + '33'};
  height: 0.3125rem;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const ThumbnailOverlay = styled.div`
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.5625rem;
  bottom: 1.75rem;
  left: 1.5rem;
  right: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const DurationAndPlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 1.5rem;
  align-items: flex-end;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  align-self: flex-end;
  display: flex;
`;

const Duration = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  align-self: flex-end;
  z-index: 3;
`;

export const PlayIconWrapper = styled.div`
  position: relative;
  right: 0;
  z-index: 3;
  cursor: pointer;
`;

export interface VideoPlayerProps {
  src: string;
  title: string;
  duration: string;
  thumbnail?: string;
  loop?: boolean;
  showRewindAndForward?: boolean;
  showProgress?: boolean;
  className?: string;
  autoPlay?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  thumbnail,
  title,
  duration,
  showRewindAndForward = false,
  showProgress = false,
  className,
  loop,
  autoPlay,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [progress, setProgress] = useState(0);
  const [showThumbnail, setShowThumbnail] = useState(!autoPlay);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
    }
  };

  const handlePlay = () => {
    setShowThumbnail(false);
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const rewind = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const forward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  return (
    <VideoContainer>
      {showThumbnail && (
        <>
          <ThumbnailOverlay onClick={handlePlay}>
            <Title variant="h5" color={theme.colors.white}>
              {title}
            </Title>
            <DurationAndPlayWrapper>
              <PlayIconWrapper onClick={handlePlay}>
                <PlayOnThumbnailIcon />
              </PlayIconWrapper>
              <Duration variant="h5" color={theme.colors.white}>
                {duration}
              </Duration>
            </DurationAndPlayWrapper>
          </ThumbnailOverlay>
        </>
      )}
      <VideoElement
        ref={videoRef}
        controls={false}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
        className={className}
        loop={loop}
        key={src}
        poster={thumbnail}
        autoPlay={autoPlay}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoElement>
      {progress > 0 && (
        <ControlWrapper {...(!showRewindAndForward && { onClick: togglePlay })} showPointer={!showRewindAndForward}>
          <Controls>
            {showRewindAndForward && (
              <RewindButton onClick={rewind}>
                <RewindIcon />
                <Typography variant="caption">10 Sec</Typography>
              </RewindButton>
            )}
            <PlayButton onClick={togglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
              <Typography variant="caption">{isPlaying ? 'Pause' : 'Play'}</Typography>
            </PlayButton>
            {showRewindAndForward && (
              <ForwardButton onClick={forward}>
                <ForwardIcon />
                <Typography variant="caption">10 Sec</Typography>
              </ForwardButton>
            )}
          </Controls>
        </ControlWrapper>
      )}
      {showProgress && (
        <ProgressBarWrapper>
          <ProgressBar progress={progress} />
        </ProgressBarWrapper>
      )}
    </VideoContainer>
  );
};

export default VideoPlayer;
