import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { medium } } }) => `
    width: ${medium};
    height: ${medium};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const ChevronRight = ({ className = '', color }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 17L12 9L19 17L20 16C18.31 13.87 13.589 8.72111 12 7C10.838 8.27778 11.98 7.03111 4 16L5 17Z" fill={color} stroke={color}/>
  </Svg>
);

export default ChevronRight;
