import { Config } from 'app/config';

import snakeify from 'app/utils/snakeify';

export enum GACustomEvents {
  LESSON_VIEW = 'lesson_view',
}

type TrackEventProps = {
  event: GACustomEvents,
  metadata?: {
    [key: string]: any,
  },
};

class GoogleAnalytics {
  private environment: string;

  constructor(config: Config) {
    this.environment = config.REACT_APP_ENVIRONMENT;
  }

  trackEvent(props: TrackEventProps) {
    const { event, metadata } = props;

    const data = snakeify({ event, ...metadata });

    this.environment === 'production' ? 
      window.dataLayer.push(data) :
      console.info(data);
  }
}

export default GoogleAnalytics;
