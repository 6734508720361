import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const SuspensionTrainer = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0673 4.90612V0H11.5673V4.90612H10.5958C9.20278 4.90612 8.0298 5.9477 7.86507 7.33092L7.13023 13.5014C6.98794 13.5954 6.8615 13.7217 6.76178 13.8802L4.19396 17.9621C4.09756 18.1153 4.03919 18.277 4.01423 18.4389H4.00345V18.5331C3.99881 18.5946 3.99889 18.656 4.00345 18.7167V19.9389V20.9029C4.00345 22.5598 5.34659 23.9029 7.00345 23.9029H8.63523C10.2921 23.9029 11.6352 22.5598 11.6352 20.9029V19.9389V18.7291C11.6411 18.6603 11.6412 18.5906 11.6352 18.5207V18.4389H11.6254C11.6005 18.277 11.5421 18.1153 11.4457 17.9621L8.87789 13.8802C8.80735 13.7681 8.72344 13.6721 8.63001 13.5922L9.35454 7.5083C9.42942 6.87957 9.96259 6.40612 10.5958 6.40612H14.1403C14.7735 6.40612 15.3067 6.87957 15.3815 7.5083L16.0995 13.5373C15.9782 13.6267 15.87 13.741 15.7825 13.8802L13.2147 17.9621C13.1183 18.1153 13.0599 18.277 13.0349 18.4389H13.0242V18.5331C13.0195 18.5946 13.0196 18.656 13.0242 18.7167V19.9389V20.9029C13.0242 22.5598 14.3673 23.9029 16.0242 23.9029H17.6559C19.3128 23.9029 20.6559 22.5598 20.6559 20.9029V19.9389V18.7291C20.6618 18.6603 20.6619 18.5906 20.6559 18.5207V18.4389H20.6462C20.6212 18.277 20.5628 18.1153 20.4664 17.9621L17.8986 13.8802C17.8187 13.7532 17.7216 13.6468 17.613 13.5612L16.871 7.33092C16.7063 5.9477 15.5333 4.90612 14.1403 4.90612H13.0673ZM5.70463 18.3777L7.81983 15.0153L9.93503 18.3777H5.70463ZM14.7254 18.3777L16.8406 15.0153L18.9558 18.3777H14.7254ZM10.1352 19.9389H5.50345V20.9029C5.50345 21.7313 6.17502 22.4029 7.00345 22.4029H8.63523C9.46366 22.4029 10.1352 21.7313 10.1352 20.9029V19.9389ZM14.5242 19.9389H19.1559V20.9029C19.1559 21.7313 18.4844 22.4029 17.6559 22.4029H16.0242C15.1957 22.4029 14.5242 21.7313 14.5242 20.9029V19.9389Z" fill={color}/>
  </Svg>
);

export default SuspensionTrainer;
