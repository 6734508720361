import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { useAppState } from 'state';
import useCheckOnboarding from 'app/hooks/use-check-onboarding';
import { routes } from 'app/routes/constants';

import Loading from 'app/ui/components/molecules/loading';
import Error from 'app/ui/components/molecules/error';

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { isOnboardedGymType, isOnboardedGymEquipment, isOnboardStatusLoaded, error } = useCheckOnboarding();
  const signedIn = useAppState(({ auth }) => auth.signedIn);

  useEffect(() => {
    if (!signedIn) {
      navigate(routes.SIGNIN);
    } else if (signedIn && isOnboardStatusLoaded && !isOnboardedGymType) {
      navigate(routes.ONBOARDING_GYM_TYPE);
    } else if (signedIn && isOnboardStatusLoaded && isOnboardedGymType && !isOnboardedGymEquipment) {
      navigate(routes.ONBOARDING_GYM_EQUIPMENT);
    }
  }, [signedIn, isOnboardedGymType, isOnboardedGymEquipment, isOnboardStatusLoaded]);

  if (!isOnboardStatusLoaded) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return <Outlet />;
};
