import React, { useState } from 'react';
import styled from 'styled-components';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppState, useDispatch } from 'state';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';

import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import Typography from 'app/ui/components/atoms/typography';
import { PillFieldSet, Pills } from 'app/ui/components/molecules/pill-field-set';

import PageTitle from 'app/ui/components/molecules/page-title';
import EditProfile from 'app/ui/components/molecules/edit-profile';
import ViewProfile from 'app/ui/components/molecules/view-profile';
import GenericPage from 'app/ui/components/layouts/generic-page';
import WorkoutList from 'app/ui/components/molecules/workout-list';

import { signout } from 'actions/auth';
import { onboardStatusReset } from 'actions/onboard';
import { resetLanguage } from 'actions/language';

import { routes } from 'app/routes/constants';

import { ProfilePage as PROFILE } from 'app/pages/profile/profile.gql';
import { PatchUser as PATCH_USER } from 'app/pages/profile/patch-user.gql';
import { PatchGym as PATCH_GYM } from 'app/pages/profile/patch-gym.gql';
import {
  GymType,
  Equipment,
  PatchUserMutation,
  PatchUserMutationVariables,
  PatchGymMutation,
  PatchGymMutationVariables,
  ProfilePageQuery,
  ProfilePageQueryVariables,
} from 'app/types/graphql';

import { lessonToWorkout } from 'app/pages/workouts';

export type UserFormData = {
  id: number;
  firstName: string;
  lastName: string;
  password: string;
  confirmedPassword: string;
};

export type GymFormData = {
  equipment: Equipment[];
  type: GymType;
};

type ProfileWithUserProps = {
  userId: number;
};

type ProfilePageWrapperProps = {
  userId: number;
  profileData: ProfilePageQuery;
};

enum MyLibraryType {
  SAVED = 'SAVED',
  COMPLETE = 'COMPLETE',
};

const WorkoutListWrapper = styled.div`
  margin-top: 1.5rem;
`;

const MyLibraryHeader = styled(Typography)`
  margin-top: 5rem;
`;

const ProfilePageWrapper: React.FC<ProfilePageWrapperProps> = ({
  userId,
  profileData: { userById, allEquipment, allGymTypes },
}) => {
  const [editMode, setEditMode] = useState(false);
  const [myLibraryVisible, setMyLibraryVisible] = useState<MyLibraryType>(MyLibraryType.SAVED)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useApolloClient();

  const intl = useIntl();

  const initialUserFormData: UserFormData = {
    id: userId,
    firstName: userById?.firstName || '',
    lastName: userById?.lastName || '',
    password: '',
    confirmedPassword: '',
  };

  const initialGymFormData: GymFormData = {
    type: userById?.gym?.type as GymType,
    equipment: userById?.gym?.equipment as Equipment[],
  };

  const canEditGym = userById?.permissions?.canManageGym || false;

  const [userDetails, setUserDetails] = useState<UserFormData>(initialUserFormData);
  const [gymDetails, setGymDetails] = useState<GymFormData>(initialGymFormData);

  const [isPatchLoading, setIsPatchLoading] = useState<boolean>(false);
  const [isPatchError, setIsPatchError] = useState<boolean>(false);

  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  const [patchUserById] = useMutation<PatchUserMutation, PatchUserMutationVariables>(PATCH_USER, {
    onCompleted: () => {
      setUserDetails({ ...userDetails, password: '', confirmedPassword: '' });
    },
  });

  const [patchGymById] = useMutation<PatchGymMutation, PatchGymMutationVariables>(PATCH_GYM, {
    onCompleted: () => {
      setIsPatchLoading(false);
      setFormErrorMessage('');
    },
  });

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleCancelButtonClick = () => {
    setUserDetails({ ...initialUserFormData });
    setEditMode(false);
  };

  const handleSignoutButtonClick = async () => {
    await client.clearStore();
    dispatch(signout());
    dispatch(onboardStatusReset());
    dispatch(resetLanguage());
    navigate(routes.SIGNIN);
    return;
  };

  const handleSaveProfileButtonClick = async () => {
    if (userDetails.password.length > 0 && userDetails.password !== userDetails.confirmedPassword) {
      const matchingPasswordsMessage = intl.formatMessage({ id: 'profile.messages.passwords.matching' });
      return setFormErrorMessage(matchingPasswordsMessage);
    }

    setIsPatchLoading(true);

    const { confirmedPassword, id, ...validUserDetails } = userDetails;

    try {
      await patchUserById({ variables: { id: userId, input: validUserDetails } });
      if (canEditGym && userById?.gym?.id) {
        const gymInput = { gymType: { id: gymDetails.type.id } };
        await patchGymById({ variables: { id: userById.gym.id, input: gymInput } });
      }
      setIsPatchLoading(false);
      setFormErrorMessage('');
      setEditMode(false);
    } catch (error) {
      setIsPatchLoading(false);
      setIsPatchError(true);
    }
  };

  if (isPatchLoading) {
    return <Loading />;
  }

  if (isPatchError) {
    return <Error />;
  }

  const savedWorkouts = userById?.savedLessons?.map((lesson) => lessonToWorkout(lesson)) || [];
  const completedWorkouts = userById?.completedLessons?.map((lesson) => lessonToWorkout(lesson)) || [];

  const myLibraryPills: Pills = Object.values(MyLibraryType).map((type) => ({
    id: type,
    name: intl.formatMessage({ id: `myLibrary.labels.${type.toLowerCase()}` }),
    isChecked: myLibraryVisible === type,
  }));

  const profileHeader = intl.formatMessage({ id: 'profile.header' });
  const myLibraryHeader = intl.formatMessage({ id: 'myLibrary.header' });

  return (
    <GenericPage>
      <PageTitle>
        <Typography variant="h2">{profileHeader}</Typography>
      </PageTitle>
      {editMode ? (
        <EditProfile
          gymDetails={gymDetails}
          userDetails={userDetails}
          allEquipment={allEquipment as Equipment[]}
          allGymTypes={allGymTypes as GymType[]}
          handleCancelButtonClick={handleCancelButtonClick}
          handleSaveProfileButtonClick={handleSaveProfileButtonClick}
          handleUserDetailsChange={setUserDetails}
          handleGymDetailsChange={setGymDetails}
          errorMessage={formErrorMessage}
          canEditGym={canEditGym}
        />
      ) : (
        <>
          <ViewProfile
            handleEditButtonClick={handleEditButtonClick}
            handleSignoutButtonClick={handleSignoutButtonClick}
            userDetails={userById}
          />
          <MyLibraryHeader variant='h2'>{myLibraryHeader}</MyLibraryHeader>
          <PillFieldSet
            label=""
            pills={myLibraryPills}
            onChange={(event) => setMyLibraryVisible(event.target.value as MyLibraryType)}
          />
          <WorkoutListWrapper>
            <WorkoutList
              workouts={myLibraryVisible === MyLibraryType.SAVED ? savedWorkouts : completedWorkouts}
            />
          </WorkoutListWrapper>
        </>
      )}
    </GenericPage>
  );
};

const ProfileWithUser: React.FC<ProfileWithUserProps> = ({ userId }) => {
  const { data, loading, error } = useQuery<ProfilePageQuery, ProfilePageQueryVariables>(PROFILE, {
    variables: { id: userId },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.userById || !data.allEquipment || !data.allGymTypes) {
    return <Error />;
  }

  const { userById, allEquipment, allGymTypes } = data;

  return <ProfilePageWrapper profileData={{ userById, allEquipment, allGymTypes }} userId={userId} />;
};

const Profile: React.FC = () => {
  const userId = useAppState(({ auth }) => auth.userId);

  if (!userId) {
    return <Error />;
  }

  return <ProfileWithUser userId={userId} />;
};

export default Profile;
