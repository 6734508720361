import { useQuery } from '@apollo/client';
import { CheckOnBoardingQuery, CheckOnBoardingQueryVariables } from 'app/types/graphql';
import { useEffect } from 'react';
import { useAppState, useDispatch } from 'state';
import CHECK_ONBOARDING from 'app/hooks/use-check-onboarding/check-onboarding.gql';

import { onboardGymEquipment, onboardGymType, onboardStatusLoaded } from 'actions/onboard';

function useCheckOnboarding() {
  const dispatch = useDispatch();
  const userId = useAppState(({ auth }) => auth.userId);
  const { gymType, gymEquipment, loaded } = useAppState(({ onboard }) => onboard);
  const { data, loading, error } = useQuery<CheckOnBoardingQuery, CheckOnBoardingQueryVariables>(CHECK_ONBOARDING, {
    variables: {
      id: Number(userId),
    },
  });
  useEffect(() => {
    if (!loading && !error && data) {
      dispatch(onboardStatusLoaded());
      if (data.userById?.gym?.type) {
        dispatch(onboardGymType());
      }
      if (data.userById?.gym?.equipment && data.userById.gym.equipment.length > 0) {
        dispatch(onboardGymEquipment());
      }
    }
  }, [data, loading, error]);

  return {
    isOnboardedGymType: gymType,
    isOnboardedGymEquipment: gymEquipment,
    isOnboardStatusLoaded: loaded,
    error: error,
  };
}

export default useCheckOnboarding;
