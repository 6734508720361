import React from 'react';
import { useIntl } from 'react-intl';

import Button from 'app/ui/components/atoms/button';
import ProfileDetails from 'app/ui/components/molecules/profile-details';

import { ProfilePageQuery } from 'app/types/graphql';
import { ViewPageButtonWrapper } from 'app/ui/components/molecules/profile-button-wrapper';

interface renderViewProfileArgs {
  handleEditButtonClick: () => void;
  handleSignoutButtonClick: () => void;
  userDetails: ProfilePageQuery['userById'];
}

const ViewProfile: React.FC<renderViewProfileArgs> = ({
  handleEditButtonClick,
  handleSignoutButtonClick,
  userDetails,
}) => {
  const intl = useIntl();

  const editProfileLabel = intl.formatMessage({ id: 'profile.buttons.edit' });
  const signOutLabel = intl.formatMessage({ id: 'profile.buttons.signOut' });

  return (
    <>
      <ProfileDetails userDetails={userDetails} />
      <ViewPageButtonWrapper>
        <Button label={editProfileLabel} type="secondary-white" onClick={handleEditButtonClick} />
        <Button label={signOutLabel} type="secondary-white" onClick={handleSignoutButtonClick} />
      </ViewPageButtonWrapper>
    </>
  );
};

export default ViewProfile;
