import { Language } from 'app/types/graphql';

export enum LanguageActionTypes {
  SET_LANGUAGE = 'SET_LANGUAGE',
  RESET_LANGUAGE = 'RESET_LANGUAGE',
}

export type SetLanguageAction = {
  type: LanguageActionTypes.SET_LANGUAGE,
  payload: Language,
};

export type ResetLanguageAction = {
  type: LanguageActionTypes.RESET_LANGUAGE,
};

export type LanguageActions = SetLanguageAction | ResetLanguageAction;

export const setLanguage = (language: Language): SetLanguageAction => ({
  type: LanguageActionTypes.SET_LANGUAGE,
  payload: language,
});

export const resetLanguage = (): ResetLanguageAction => ({
  type: LanguageActionTypes.RESET_LANGUAGE,
});

export default { setLanguage, resetLanguage };
