import styled from 'styled-components';

export const ViewPageButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  button {
    width: 50%;
    justify-content: center;
    max-width: 11rem;
  }
`;

export const EditPageButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  button {
    flex: 1;
  }
`;
