import { SectionExercise } from "app/types/graphql";

const distanceLengthFormatter = (value: number) => {
  if (!value) {
    return null;
  }

  if (value === -1) {
    return `MAX M`
  }

  return `${value} M`;
}

const repsLengthFormatter = (value: number) => {
  if (!value) {
    return null;
  }

  if (value === -1) {
    return `MAX REPS`
  }

  return `${value}x`;
}

export const lengthFromDurationSeconds = (duration: number, showSecs = false) => {
  if (duration === -1) {
    return 'MAX MINS';
  }
  const wholeMinutes = Math.floor(duration / 60);
  const minutes = Math.ceil(duration / 60);
  const seconds = Math.ceil(duration % 60);

  if (showSecs) {
    return `${wholeMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return `${minutes} ${minutes === 1 ? 'MIN' : 'MINS'}`;
}

export const minutesToSeconds = (durationInMinutes: number) => durationInMinutes * 60;

export const lengthFromExercise = ({ duration, distance, repetitions }: Partial<SectionExercise>) => {
  const durationLength = duration ? lengthFromDurationSeconds(duration, true) : null;
  const repetitionsLength = repetitions ? repsLengthFormatter(repetitions) : null;
  const distanceLength = distance ? distanceLengthFormatter(distance) : null;

  const lengths = [durationLength, repetitionsLength, distanceLength].filter((len) => !!len);

  return lengths.join(' / ');
}
