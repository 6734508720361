import React, { RefObject } from 'react';

import { PillFieldSet, Pills, PillOnChangeEvent } from 'app/ui/components/molecules/pill-field-set';
import { DropDownFieldSet, DropDownOnChangeEvent } from 'app/ui/components/molecules/drop-down-field-set';
import { TextFieldSet } from 'app/ui/components/molecules/text-field-set';

import { Option } from 'app/ui/components/atoms/drop-down';

interface PillTypeProps {
  label: string;
  type: 'pill';
  pills: Pills;
  onChange: PillOnChangeEvent;
  placeholder?: never;
  helperText?: never;
  inputRef?: never;
  errorText?: never;
  value?: never;
  disabled?: boolean;
  readonly?: never;
  labelColor?: string;
  noValueText?: never;
  icon?: never;
  onTypeToggle?: never;
}

interface DropDownTypeProps {
  label: string;
  type: 'dropdown';
  options: Option[];
  noValueText: string;
  onChange: DropDownOnChangeEvent;
  placeholder?: never;
  helperText?: never;
  inputRef?: never;
  errorText?: never;
  value?: string;
  disabled?: boolean;
  readonly?: never;
  labelColor?: string;
  onTypeToggle?: never;
  icon?: never;
}

interface OtherTypeProps {
  label: string;
  onChange: (value: string) => void; // required when type is not 'pill'
  type: Exclude<'text' | 'password' | 'email' | 'number' | 'tel' | 'url', 'pill' | 'dropdown'>;
  placeholder?: string;
  helperText?: string;
  inputRef?: RefObject<HTMLInputElement>;
  errorText?: string | null;
  value?: string;
  disabled?: boolean;
  readonly?: boolean;
  labelColor?: string;
  noValueText?: never;
  onTypeToggle?: () => void,
  icon?: JSX.Element;
}

export type FormFieldSetProps = PillTypeProps | DropDownTypeProps | OtherTypeProps;

const FormFieldSet: React.FC<FormFieldSetProps> = (props) => {
  const { label, type, placeholder, helperText, inputRef, errorText, value, labelColor, disabled, readonly, onChange, noValueText, onTypeToggle, icon } =
    props;

  if (type === 'pill' && 'pills' in props) {
    return <PillFieldSet pills={props.pills} label={label} disabled={disabled} onChange={onChange} />;
  } if (type === 'dropdown' && 'options' in props) {
    return <DropDownFieldSet options={props.options} label={label} disabled={disabled} onChange={onChange} value={value} noValueText={noValueText} />;
  } if (type !== 'pill' && type !== 'dropdown' && typeof props.onChange === 'function') {
    return (
      <TextFieldSet
        label={label}
        labelColor={labelColor}
        type={type}
        placeholder={placeholder}
        helperText={helperText}
        inputRef={inputRef}
        errorText={errorText}
        onChange={props.onChange}
        value={value}
        disabled={disabled}
        readonly={readonly}
        onTypeToggle={onTypeToggle}
        icon={icon}
      />
    );
  }
  console.error('Invalid props passed to FormFieldSet');
  return null;
};

export default FormFieldSet;
