import React from 'react';
import styled from 'styled-components';

import { Theme } from 'app/providers/theme';
import Typography from 'app/ui/components/atoms/typography';

import {
  SectionExercise,
  SectionExerciseGroup,
  LessonSection as WorkoutSection,
} from 'app/types/graphql';

import { indexToLetter, WorkoutType } from 'app/pages/workout/util';
import { HandleSectionFocusChangeProps } from 'app/pages/workout';
import { lengthFromDurationSeconds, lengthFromExercise } from 'app/utils/duration-handler';
import SectionFormatIconWithTooltip from 'app/ui/components/atoms/icons/section-formats/icon-with-tooltip';

import ContentItemAreasWrapper, {
  ContentData,
  ContentItemData,
  GroupContentItemData,
} from 'app/ui/components/molecules/content-item-areas-wrapper';

const MobileSectionDuration = styled(Typography)`
  margin-bottom: 1.5rem;
  weight: bold;
`;

type SectionExerciseToContentItemDataArgs = {
  exercisePosition: string,
  sectionExercise: SectionExercise,
};

const sectionExerciseToContentItemData = ({
  sectionExercise,
}: SectionExerciseToContentItemDataArgs): ContentItemData => ({
  title: sectionExercise.exercise.name,
  content: sectionExercise.exercise.description,
  extraContent: sectionExercise.description,
  additionalInfo: lengthFromExercise(sectionExercise),
  notes: sectionExercise.notes,
  id: sectionExercise.id,
  video: sectionExercise.exercise?.video,
});

type SectionExerciseGroupToContentItemDataArgs = {
  position: number,
  sectionExerciseGroup: SectionExerciseGroup,
};

const sectionExerciseGroupToContentItemData = ({
  sectionExerciseGroup,
}: SectionExerciseGroupToContentItemDataArgs): GroupContentItemData => ({
  title: sectionExerciseGroup.name,
  content: sectionExerciseGroup.description,
  additionalInfo: lengthFromDurationSeconds(sectionExerciseGroup.duration || 0),
  id: sectionExerciseGroup.id,
  showTitle: sectionExerciseGroup.showTitle,
  sectionExercises: sectionExerciseGroup?.sectionExercises?.map((sectionExercise, index) => (
    sectionExerciseToContentItemData({ sectionExercise, exercisePosition: indexToLetter(index) })
  ))|| [],
  notes: sectionExerciseGroup.notes,
});

type WorkoutDataProps = {
  contentItemFocused?: string | null,
  handleContentItemClick?: (prop: HandleSectionFocusChangeProps) => void,
  isMobile?: boolean,
  theme?: Theme,
  dataOnly?: boolean,
};

type WorkoutToDataArgs = WorkoutDataProps & {
  workout: WorkoutType,
};

type SectionToDataArgs = WorkoutDataProps & {
  lessonSection: WorkoutSection,
};

export const sectionToData = ({
  lessonSection,
  theme,
  isMobile = false,
  dataOnly = false,
  contentItemFocused = null,
  handleContentItemClick = (() => {}),
}: SectionToDataArgs): ContentData => {
  const sectionExerciseGroupsData = lessonSection?.sectionExerciseGroups
    ?.map((sectionExerciseGroup, index) => (
      sectionExerciseGroupToContentItemData({ sectionExerciseGroup, position: index + 1 })
    ))|| [];

  const duration = lengthFromDurationSeconds(lessonSection.duration);

  return {
    title: lessonSection.name,
    titleExtraContent: <SectionFormatIconWithTooltip
      format={lessonSection.format}
      isRotational={lessonSection.isRotational}
    />,
    content: isMobile && !dataOnly
      ? (
        <>
          <MobileSectionDuration
            variant="body2"
            color={theme?.colors.paragraphGray}
          >
            Section duration: <b>{duration.toLowerCase()}</b>
          </MobileSectionDuration>
          {lessonSection.description}
        </>
      )
      : lessonSection.description,
    notes: lessonSection.notes,
    additionalInfo: duration,
    format: lessonSection.format,
    id: lessonSection.id,
    ...(dataOnly
      ? { groups: sectionExerciseGroupsData }
      : {
          contentItems: <ContentItemAreasWrapper
            items={sectionExerciseGroupsData}
            contentItemFocused={contentItemFocused}
            handleContentItemClick={handleContentItemClick}
          />
        }
    ),
  }
};

export const workoutToData = ({
  workout,
  contentItemFocused,
  handleContentItemClick,
  isMobile,
  theme,
  dataOnly = false,
}: WorkoutToDataArgs): ContentData[] => (
  (workout?.sections || []).map((lessonSection) => sectionToData({
    lessonSection,
    contentItemFocused,
    handleContentItemClick,
    isMobile,
    theme,
    dataOnly,
  }))
);
