import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Typography from 'app/ui/components/atoms/typography';
import { generateBreadcrumbs } from 'app/utils/breadcrumbs';

import { useIntl } from 'react-intl';

const Wrapper = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  margin: ${({ theme }) => `0 0 ${theme.spacing.jumbo}`};

  & > :last-child {
    word-break: break-word;
  }
`;

type BreadcrumbProps = {
  contentTitle: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ contentTitle }) => {
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  
  const breadcrumbs = generateBreadcrumbs({ intl, pathname: location.pathname, contentTitle });

  return (
    <Wrapper>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={`bcb-${index}`}>
          {index > 0 && (
            <Typography key={`sep-${index}`} variant="body2" color={theme.colors.lightGray}>
              &gt;
            </Typography>
          )}
          {breadcrumb.href ? (
            <Link to={breadcrumb.href}>
              <Typography variant="body2" color={theme.colors.lightGray}>
                {breadcrumb.title}
              </Typography>
            </Link>
          ) : (
            <Typography variant="body2" color={theme.colors.lightGray}>
              {breadcrumb.title}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Breadcrumb;
