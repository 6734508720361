import { Theme } from 'app/providers/theme';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

type ButtonTypes = 'primary' | 'secondary' | 'secondary-white' | 'curved-grey' | 'curved' ;
type ButtonStates = 'default' | 'disabled';
type IconPosition = 'left' | 'right';

export interface Props {
  label: string;
  state?: ButtonStates;
  iconPosition?: IconPosition;
  icon?: JSX.Element;
  onClick?: () => void;
  type?: ButtonTypes;
  fullWidthMob?: boolean;
  className?: string;
}

interface BaseButtonProps {
  theme: Theme;
  type?: ButtonTypes;
  fullWidthMob?: boolean;
}

interface IconWrapperProps {
  theme: Theme;
  $iconPosition?: IconPosition;
}

const BaseButton = styled.button<BaseButtonProps>`
  ${({ theme, type, fullWidthMob }) => {
    const { typography, colors, spacing, media } = theme;

    const getBorderColor = () => {
      switch (type) {
        case 'secondary':
          return colors.focoltone;
        case 'secondary-white':
          return colors.white;
        case 'curved-grey':
          return colors.blackSwan;
        default:
          return 'transparent';
      }
    };

    const getBackgroundColor = () => {
      switch (type) {
        case 'secondary':
        case 'secondary-white':
        case 'curved-grey':
          return 'transparent';
        case 'curved':
          return colors.focoltoneWithTransparency;
        default:
          return colors.focoltone;
      }
    };

    const getHoverColor = () => {
      switch (type) {
        case 'secondary':
          return colors.caparol;
        case 'secondary-white':
        case 'curved':
        case 'curved-grey':
          return colors.knop;
        default:
          return colors.black;
      }
    };

    const getHoverBackgroundColor = () => {
      switch (type) {
        case 'primary':
          return colors.caparol;
        case 'curved-grey':
          return colors.black;
        case 'curved':
          return colors.focoltoneWithTransparencyHover;
        default:
          return 'transparent';
      }
    };

    const getBorderRadius = () => {
      if (type?.includes('curved')) {
        return '1.5rem';
      }
      return '0.5rem';
    }

    return `
      align-items: baseline;
      background: ${getBackgroundColor()};
      border-radius: ${getBorderRadius()};
      border: 0.0625rem solid ${getBorderColor()};
      cursor: pointer;
      line-height: ${typography.lineHeight};
      padding: ${spacing.xsmall} ${spacing.medium};

      &:hover {
        background: ${getHoverBackgroundColor()};
        border: 0.0625rem solid ${getHoverColor()};
        color: ${getHoverColor()};
      }

      &:active {
        border: 0.0625rem solid ${colors.pascol};
        color: ${colors.pascol};
      }

      &:disabled {
        border: 0.0625rem solid ${colors.blackSwan};
        color: ${colors.knop};
        cursor: not-allowed;
        background: ${colors.blackSwan};
      }

      ${media.medium(
        (theme: Theme) => `
        padding: ${theme.spacing.xsmall} ${theme.spacing.large};
      `,
      )}

      ${fullWidthMob && `
        width: 100%;
        justify-content: center;
        ${media.medium(
          () => `
            width: auto;
            justify-content: initial;
        `,
        )}
      `}
    `;
  }}
`;

const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-flex;
  align-items: center;

  ${({ $iconPosition, theme: { spacing } }) =>
    $iconPosition === 'right' ? `margin-left: ${spacing.xsmall};` : `margin-right: ${spacing.xsmall};`}
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Button: React.FC<Props> = ({
  label,
  state = 'default',
  iconPosition = 'right',
  icon,
  onClick,
  type = 'primary',
  fullWidthMob,
  className,
}) => {
  const isIconLeft = icon && iconPosition === 'left';
  const isIconRight = icon && iconPosition === 'right';
  const { colors } = useTheme();

  const getTextColor = () => {
    switch (type) {
      case 'secondary':
        return colors.focoltone;
      case 'secondary-white':
      case 'curved':
      case 'curved-grey':
        return colors.white;
      default:
        return colors.black;
    }
  };

  const getTypographyVariant = () => {
    switch (type) {
      case 'curved':
      case 'curved-grey':
        return 'caption';
      default:
        return 'button';
    }
  }

  return (
    <BaseButton
      type={type}
      onClick={onClick}
      disabled={state === 'disabled'}
      fullWidthMob={fullWidthMob}
      className={className}
    >
      <LabelWrapper>
        {isIconLeft && <IconWrapper $iconPosition={iconPosition}>{icon}</IconWrapper>}
        <Typography variant={getTypographyVariant()} color={getTextColor()}>{label}</Typography>
        {isIconRight && <IconWrapper $iconPosition={iconPosition}>{icon}</IconWrapper>}
      </LabelWrapper>
    </BaseButton>
  );
};

export default Button;
