import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useAppState, useDispatch } from 'state';
import { useIntl } from 'react-intl';

import EntryLayout from 'app/ui/components/layouts/entry';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';
import { Steps } from 'app/ui/components/atoms/steps';

import RadioBoxFieldSet, { FieldSetType } from 'app/ui/components/molecules/radio-box-field-set';
import Loading from 'app/ui/components/molecules/loading';
import Error from 'app/ui/components/molecules/loading';

import {
  GymsQuery,
  GymsQueryVariables,
  PatchGymTypeByGymMutation,
  PatchGymTypeByGymMutationVariables,
} from 'app/types/graphql';

import GYMS from 'app/pages/onboarding-gym/gyms.gql';
import PATCH_GYM_TYPE_TO_GYM from 'app/pages/onboarding-gym/patch-user-to-gym.gql';

import { routes } from 'app/routes/constants';
import { onboardGymType } from 'actions/onboard';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  button {
    width: 100%;
    justify-content: center;
  }
`;

const RadioBoxFieldSetWrapper = styled.div`
  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
      padding: 0 3rem;
      `,
    )}
  `}
`;

type OnBoardingPageProps = {
  userId: number;
};

const OnboardingPage = ({ userId }: OnBoardingPageProps) => {
  const dispatch = useDispatch();
  const { gymType, gymEquipment, loaded: onboardStatusLoaded } = useAppState(({ onboard }) => onboard);
  const [currentGymId, setCurrentGymId] = useState(0);
  const [canUserManageGym, setCanUserManageGym] = useState(false);
  const [allGyms, setAllGyms] = useState<FieldSetType>([]);
  const [selectedGymType, setSelectedGymType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const intl = useIntl();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGymType(event.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await patchGymTypeToGym({
        variables: {
          id: currentGymId,
          input: {
            gymType: {
              id: Number(selectedGymType),
            },
          },
        },
      });
    } catch (e) {
      console.error('Error patching user to gym:', e);
    }
  }, [selectedGymType]);

  const { data, loading, error } = useQuery<GymsQuery, GymsQueryVariables>(GYMS, {
    variables: {
      id: Number(userId),
    },
  });

  const [patchGymTypeToGym] = useMutation<PatchGymTypeByGymMutation, PatchGymTypeByGymMutationVariables>(
    PATCH_GYM_TYPE_TO_GYM,
    {
      onCompleted: () => {
        setIsLoading(false);
        dispatch(onboardGymType());
      },
      onError: (error) => {
        setIsLoading(false);
        setHasError(true);
      },
    },
  );

  const gyms = useMemo(
    () => data?.allGymTypes?.map((gym) => ({ id: String(gym?.id), name: gym?.name, isChecked: false })),
    [data?.allGymTypes],
  );

  useEffect(() => {
    setIsLoading(loading);
    setHasError(!!error);

    if (data?.userById?.permissions?.canManageGym) {
      setCanUserManageGym(data?.userById?.permissions?.canManageGym);
    }

    if (data?.userById?.gym?.id) {
      setCurrentGymId(data?.userById?.gym?.id);
    }

    if (!!gyms?.length) {
      setAllGyms(gyms);
    }
  }, [data]);

  const { colors } = useTheme();

  if (isLoading) {
    return <Loading />;
  }

  if (hasError) {
    return <Error />;
  }

  if ((onboardStatusLoaded && gymType && gymEquipment) || !canUserManageGym) {
    return <Navigate to={routes.HOME} />;
  }

  if (onboardStatusLoaded && gymType && !gymEquipment) {
    return <Navigate to={routes.ONBOARDING_GYM_EQUIPMENT} />;
  }

  const question = intl.formatMessage({ id: 'onboardingGym.questions.gymType' });
  const actionLabel = intl.formatMessage({ id: 'onboardingGym.actions.continue' });

  return (
    <EntryLayout $showBrandImage={false}>
      <Steps totalSteps={2} currentStep={1} />
      <Typography variant="h4">{question}</Typography>
      <RadioBoxFieldSetWrapper>
        <RadioBoxFieldSet fieldSet={allGyms} onChange={handleChange} selectedId={selectedGymType} />
      </RadioBoxFieldSetWrapper>
      <ButtonWrapper>
        <Button
          type="primary"
          label={actionLabel}
          onClick={handleSubmit}
          state={!!selectedGymType ? 'default' : 'disabled'}
        />
      </ButtonWrapper>
    </EntryLayout>
  );
};

const Onboarding: React.FC = () => {
  const userId = useAppState(({ auth }) => auth.userId);

  if (!userId) {
    return <Error />;
  }

  return <OnboardingPage userId={userId} />;
};

export default Onboarding;
