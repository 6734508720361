import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import useServices from 'app/services';
import { routes } from 'app/routes/constants';

import EntryLayout from 'app/ui/components/layouts/entry';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';

import FormFieldSet from 'app/ui/components/molecules/form-field-set';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  button {
    width: 100%;
    justify-content: center;
  }
`;

const TextFieldSetWrapper = styled.div`
  input {
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  text-decoration: underline;
`;

const SetPasswordPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const services = useServices();
  const [searchParams] = useSearchParams();
  const [reset, setReset] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const token = searchParams.get('token');

  const intl = useIntl();

  const password = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);
  const defaultError = useMemo(
    () => ({
      confirmPassword: null,
    }),
    [],
  );

  const [{ confirmPassword: confirmPasswordError }, setError] = useState<{
    confirmPassword: string | null;
  }>(defaultError);

  const onSubmit = useCallback(async () => {
    if (!token) {
      navigate(routes.SIGNIN);
      return;
    }

    try {
      setError(defaultError);
      if (password.current && confirmPassword.current) {
        if (password.current.value !== confirmPassword.current.value) {
          setError({
            confirmPassword: intl.formatMessage({ id: 'setPassword.errors.passwordMismatch' }),
          });
          return;
        }
        if (password.current.value.length < 7 || /\s/.test(password.current.value)) {
          setError({
            confirmPassword: intl.formatMessage({ id: 'setPassword.errors.passwordRequirements' }),
          });
          return;
        }
        await services.password.resetPassword({
          token,
          password: password.current.value,
        });

        setReset(true);
      }
    } catch (err) {
      setError({
        confirmPassword: intl.formatMessage({ id: 'setPassword.errors.errorOccurred' }),
      });
    }
  }, [services, defaultError]);

  useEffect(() => {
    if (!token) {
      navigate(routes.SIGNIN);
      return;
    }

    const checkToken = async () => {
      const { valid } = await services.password.checkResetPasswordToken({ token });

      if (!valid) {
        setLinkExpired(true);
      }
    };

    checkToken();
  }, []);

  useEffect(() => {
    if (linkExpired) {
      navigate(routes.FORGOT_PASSWORD, { state: { expired: true } });
      return;
    }

    if (reset) {
      navigate(routes.SIGNIN, { state: { reset: true } });
      return;
    }
  }, [linkExpired, reset]);

  const setPasswordHeader = intl.formatMessage({ id: 'setPassword.header' });
  const passwordCopy = intl.formatMessage({ id: 'setPassword.copy' });
  const passwordLabel = intl.formatMessage({ id: 'setPassword.labels.password' });
  const confirmPasswordLabel = intl.formatMessage({ id: 'setPassword.labels.confirmPassword' });
  const setPasswordLabel = intl.formatMessage({ id: 'setPassword.actions.setPassword' });
  const signInLabel = intl.formatMessage({ id: 'setPassword.actions.signIn' });

  return (
    <EntryLayout>
      <Typography variant="h4" color={theme.colors.white}>
        {setPasswordHeader}
      </Typography>
      <Typography variant="body2" color={theme.colors.lightGray}>
        {passwordCopy}
      </Typography>
      <TextFieldSetWrapper>
        <FormFieldSet
          type="password"
          label={passwordLabel}
          placeholder=""
          onChange={() => {}}
          inputRef={password}
        />
      </TextFieldSetWrapper>
      <TextFieldSetWrapper>
        <FormFieldSet
          type="password"
          label={confirmPasswordLabel}
          placeholder=""
          onChange={() => {}}
          inputRef={confirmPassword}
          errorText={confirmPasswordError}
        />
      </TextFieldSetWrapper>
      <ButtonWrapper>
        <Button label={setPasswordLabel} type="primary" onClick={onSubmit} />
      </ButtonWrapper>
      <LinkWrapper>
        <Link to="/sign-in">
          <Typography variant="body2" color={theme.colors.lightGray}>
            {signInLabel}
          </Typography>
        </Link>
      </LinkWrapper>
    </EntryLayout>
  );
};

export default SetPasswordPage;
