import React from 'react';
import styled from 'styled-components';

import { Fieldset } from 'app/ui/components/molecules/field-set-styled';
import RadioBox from 'app/ui/components/atoms/radio-box';

export type FieldSetType = {
  id: string;
  name: string;
  isChecked: boolean;
}[];

export interface RadioBoxFieldSetProps {
  fieldSet: FieldSetType;
  selectedId: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledFieldset = styled(Fieldset)`
  margin: 0;
`;

const RadioBoxFieldSet: React.FC<RadioBoxFieldSetProps> = ({ fieldSet, selectedId, onChange }) => {

  return (
    <StyledFieldset>
      {fieldSet.map((field) => (
        <RadioBox
          id={field.id}
          value={field.name}
          isChecked={field.id === selectedId}
          onChange={onChange}
          key={field.id + '-' + field.name}
        />
      ))}
    </StyledFieldset>
  );
};

export default RadioBoxFieldSet;
