import React, { createContext, useContext, ReactNode } from 'react';

import GoogleAnalytics from 'app/utils/analytics';
import { Config } from 'app/config';

type GoogleAnalyticsType = InstanceType<typeof GoogleAnalytics>;

const context = createContext<GoogleAnalyticsType | null>(null);
const { Provider } = context;

interface AnalyticsProviderProps {
  children: ReactNode,
  config: Config,
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children, config }) => {
  const analytics = new GoogleAnalytics(config);

  return (
    <Provider value={analytics}>
      {children}
    </Provider>
  );
};

export const useAnalytics = (): GoogleAnalyticsType | null => (
  useContext(context)
);
