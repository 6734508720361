import camelify from 'app/utils/camelify';

type FetchResponse = {
  body: any,
  status: number,
  headers: Headers,
};

export class RequestError extends Error {
  public status: number;
  public body: any;

  constructor(message: any, status = 500, body: any) {
    super(message);
    this.status = status;
    this.body = body || null;
  }
}

const getBody = async (response: any) => {
  const { status, headers } = response;

  if (status === 204) {
    return null;
  }

  const contentType = headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }

  return response.text();
};

const wrappedFetch = async (url: string, init?: any): Promise<FetchResponse> => {
  const response = await fetch(url, init);

  const responseBody = await getBody(response);
  const { status, headers } = response;

  if (!response.ok) {
    const bodyMessage = responseBody ? responseBody.error || responseBody.message || responseBody : null;
    throw new RequestError(bodyMessage || 'Unknown Error', status, responseBody)
  }

  return { body: camelify(responseBody), status, headers };
};

export default wrappedFetch;
