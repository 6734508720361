import React from 'react';

import { Equipment } from 'app/types/graphql';

import Typography from 'app/ui/components/atoms/typography';
import { getEquipmentIcon } from 'app/ui/components/atoms/icons/equipment/util';

export const getEquipmentIcons = (equipment: Equipment[], color: string) => {
  const icons = equipment.map((eq) => getEquipmentIcon({ name: eq.name, color }));

  const maxNumberOfIcons = 4;

  const additional = icons.length > maxNumberOfIcons
    ? <Typography variant='body2' color={color}>{`+${icons.length - maxNumberOfIcons}`}</Typography>
    : '';

  const iconsToShow = icons.slice(0, maxNumberOfIcons);

  return <>{iconsToShow}{additional}</>;
}
