import React, { ReactNode, Fragment } from 'react';
import DotDivider from 'app/ui/components/atoms/dot-divider';

type DotDividedElementsWrapperProps = {
  elements: ReactNode[];
  color: string;
};

const DotDividedElementsWrapper: React.FC<DotDividedElementsWrapperProps> = ({ elements, color }) => {
  const dividedElements = elements
    .map((element, index) => (
      <Fragment key={`element-${index}`}>
        {element}
        {index < elements.length - 1 && <DotDivider color={color} key={`divider-${index}`} />}
      </Fragment>
    ));

  return <>{dividedElements}</>;
};

export default DotDividedElementsWrapper;
