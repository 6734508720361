import React from 'react';
import { useIntl } from 'react-intl';

import ViewProfileField from 'app/ui/components/atoms/view-profile-field';

import {
  Equipment,
  ProfilePageQuery,
} from 'app/types/graphql';

const getEquipmentNames = (equipment: Equipment[]): string[] => (
  equipment.map((item) => (item.name))
);

const concatenateNames = (names: string[]): string => (names.join(', '));

interface profileDetailsArgs {
  userDetails: ProfilePageQuery['userById'];
}

const ProfileDetails: React.FC<profileDetailsArgs> = ({ userDetails }) => {
  const intl = useIntl();

  const nameLabel = intl.formatMessage({ id: 'profile.labels.name' });
  const gymTypeLabel = intl.formatMessage({ id: 'profile.labels.gymType' });
  const equipmentLabel = intl.formatMessage({ id: 'profile.labels.gymEquipment' });
  const emailLabel = intl.formatMessage({ id: 'profile.labels.email' });

  return (
    <>
      <ViewProfileField
        label={nameLabel}
        value={`${userDetails?.firstName} ${userDetails?.lastName}`}
        type="text"
        key="user-name"
      />
      <ViewProfileField
        label={gymTypeLabel}
        value={userDetails?.gym?.type?.name || ''}
        type="text"
        key="gym-type-name"
      />
      <ViewProfileField
        label={equipmentLabel}
        value={concatenateNames(getEquipmentNames(userDetails?.gym?.equipment || []))}
        type="text"
        key="gym-equipment-names"
      />
      <ViewProfileField
        label={emailLabel}
        value={userDetails?.email || ''}
        type="text"
        key="user-email"
      />
    </>
  );
};

export default ProfileDetails;
