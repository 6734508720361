import React from 'react';
import { useTheme } from 'styled-components';

interface IconProps {
  color?: string;
}

export const PlusIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.white;
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="11" y="2" width="2" height="20" fill={colorToUse} />
      <rect x="22" y="11" width="2" height="20" transform="rotate(90 22 11)" fill={colorToUse} />
    </svg>
  );
};

export const MinusIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.black;
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="22" y="11" width="2" height="20" transform="rotate(90 22 11)" fill={colorToUse} />
    </svg>
  );
};
