import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Kettlebell = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.71816 9.21573L8.06193 7.26667C10.3967 5.54783 13.6033 5.54783 15.9381 7.26667L15.2818 9.21573C14.3103 8.6816 13.1912 8.37727 12 8.37727C10.8088 8.37727 9.68966 8.6816 8.71816 9.21573ZM6.85473 10.7253L5.77405 7.51566C5.57419 6.92206 5.75725 6.26776 6.23711 5.86055C9.5533 3.04648 14.4467 3.04648 17.7629 5.86055C18.2428 6.26776 18.4258 6.92206 18.226 7.51566L17.1453 10.7253C18.1461 11.8866 18.75 13.3911 18.75 15.0346C18.75 17.3205 17.5819 19.3372 15.8024 20.536C15.2962 20.877 14.6827 21 14.0724 21H12H9.92756C9.31728 21 8.70376 20.877 8.19761 20.536C6.41806 19.3372 5.25 17.3205 5.25 15.0346C5.25 13.3911 5.85387 11.8866 6.85473 10.7253Z" fill={color}/>
  </Svg>
);

export default Kettlebell;
