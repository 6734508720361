import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  className?: string,
};

const CoupletDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM4 12C4 9.92893 5.67893 8.25 7.75 8.25C9.82107 8.25 11.5 9.92893 11.5 12C11.5 14.0711 9.82107 15.75 7.75 15.75C5.67893 15.75 4 14.0711 4 12ZM12.5 12C12.5 9.92893 14.1789 8.25 16.25 8.25C18.3211 8.25 20 9.92893 20 12C20 14.0711 18.3211 15.75 16.25 15.75C14.1789 15.75 12.5 14.0711 12.5 12Z" fill="black"/>
  </Svg>
);

export default CoupletDark;
