import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

import ChevronDownIcon from 'app/assets/chevron-down-icon.svg';
import ChevronUpIcon from 'app/assets/chevron-up-icon.svg';

export type Option = {
  id: string,
  name: string,
}

export type DropDownProps = {
  options: Option[];
  disabled: boolean;
  onChange: (selectedOption: Option) => void;
  noValueText: string;
  value?: string;
};

const DropDownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled.div<{disabled: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme: { spacing, colors }, disabled }) => `
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    margin-bottom: ${spacing.xsmall};
    padding: ${spacing.small} ${spacing.xlarge} ${spacing.small} ${spacing.medium};
    border-radius: ${spacing.xsmall};
    background: ${disabled ? colors.veryGray : colors.white};
  `}
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  ${({ theme: { colors, spacing } }) => `
    background: ${colors.white};
    padding: ${spacing.xsmall} 0 ${spacing.xsmall} ${spacing.medium};
    border-bottom: 1px solid ${colors.black};
    &:first-child {
      border-top-left-radius: ${spacing.xsmall};
      border-top-right-radius: ${spacing.xsmall};
    }
    &:last-child {
      border-bottom-left-radius: ${spacing.xsmall};
      border-bottom-right-radius: ${spacing.xsmall};
    }
    &:hover {
      background: ${colors.paragraphGray};
    }
  `}

`;


const DropDown: React.FC<DropDownProps> = ({ options, disabled = false, onChange, value, noValueText }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => disabled ? null : setIsOpen(!isOpen);

  const onClick = (option: Option) => {
    toggling();
    onChange(option);
  }

  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling} disabled={disabled}>
        {value ? (
          <Typography variant='body2' color={disabled ? theme.colors.lightGray : theme.colors.veryGray}>{value}</Typography>
        ) : (
          <Typography variant='body2' color={theme.colors.lightGray}>{noValueText}</Typography>
        )}
        {isOpen ? (
          <img src={ChevronUpIcon} alt="Chevron Up Icon" />
        ) : (
          <img src={ChevronDownIcon} alt="Chevron Down Icon" />
        )}
      </DropDownHeader>
      {isOpen && (
        <ul>
          {options.map((option) => (
            <ListItem id={option.id} onClick={() => onClick(option)} key={option.name}>
              <Typography variant='body2' color={theme.colors.veryGray}>{option.name}</Typography>
            </ListItem>
          ))}
        </ul>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
