import fetch from 'app/utils/requests/fetch';
import urlQueryParams from 'app/utils/requests/query-params';

type CreateAuthArgs = {
  url: string;
  clientId: string;
  clientSecret: string;
};

export type TokenPayload = {
  accessToken: string;
  accessTokenExpiresAt: number;
  refreshToken: string;
  refreshTokenExpiresAt: string;
};

type SigninCredentials = {
  username: string;
  password: string;
};

type RefreshTokenArgs = { refreshToken: string };

const createAuthService = ({ url, clientId, clientSecret }: CreateAuthArgs) => {
  if (!url) {
    throw new Error('No url to auth service supplied');
  }

  if (!clientId) {
    throw new Error('No client id for auth service supplied');
  }

  if (!clientSecret) {
    throw new Error('No client secret for auth service supplied');
  }

  const signin = async ({ username, password }: SigninCredentials): Promise<TokenPayload> => {
    const params = urlQueryParams({
      client_id: clientId,
      grant_type: 'password',
      client_secret: clientSecret,
      username,
      password,
    });

    const { body } = await fetch(url, {
      body: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });

    return body as TokenPayload;
  };

  const refreshToken = async ({ refreshToken }: RefreshTokenArgs): Promise<TokenPayload> => {
    const params = urlQueryParams({
      client_id: clientId,
      grant_type: 'refresh_token',
      client_secret: clientSecret,
      refresh_token: refreshToken,
    });

    const { body } = await fetch(url, {
      body: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });

    return body as TokenPayload;
  };

  return {
    signin,
    refreshToken,
  };
};

export type AuthService = ReturnType<typeof createAuthService>;

export default createAuthService;
