import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  className?: string,
};

const Show = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 5.79297C16.7036 5.79297 20.8011 8.61259 23 12.7936C20.8011 16.9746 16.7036 19.793 12 19.793C7.2964 19.793 3.1989 16.9746 1 12.7936C3.1989 8.61259 7.2964 5.79297 12 5.79297ZM12 8.21532C8.7121 8.21532 5.6145 9.93882 3.6389 12.7936C5.6145 15.6483 8.7121 17.3706 12 17.3706C15.2879 17.3706 18.3855 15.6483 20.3611 12.7936C18.3855 9.93882 15.2879 8.21532 12 8.21532ZM12 10.3712C13.2155 10.3712 14.2 11.4552 14.2 12.7936C14.2 14.1319 13.2155 15.2159 12 15.2159C10.7845 15.2159 9.8 14.1319 9.8 12.7936C9.8 11.4552 10.7845 10.3712 12 10.3712Z" fill="black"/>
  </Svg>
);

export default Show;
