import styled from 'styled-components';

const SlideImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  opacity: 0.1;

  ${({ theme: { media } }) => `${media.medium(() => `
      width: 40vw;
    `)}
  `};
`;

export default SlideImage;
