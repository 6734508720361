import React, { useState } from 'react';

import { useAppState, useDispatch } from 'state';
import { setLanguage } from 'actions/language';

import { useQuery, useMutation } from '@apollo/client';

import LanguagePickerComponent from 'app/ui/components/molecules/language-picker';
import Loading from 'app/ui/components/molecules/loading-with-overlay';

import { LanguagePickerWrapper as LANGUAGES } from 'app/ui/components/molecules/language-picker/languages.gql';
import { 
  UpdatePreferredLanguage as UPDATE_LANGUAGE,
} from 'app/ui/components/molecules/language-picker/update-language.gql';

import {
  Language,
  LanguagePickerWrapperQuery,
  LanguagePickerWrapperQueryVariables,
  UpdatePreferredLanguageMutation,
  UpdatePreferredLanguageMutationVariables,
} from 'app/types/graphql';

const emptyLanguagePicker = (
  <LanguagePickerComponent
    languages={[]}
    preferredLanguage={null}
    onChange={() => {}}
  />
);

const UserLanguagePickerWrapper: React.FC<{ preferredLanguage: Language }> = ({ preferredLanguage }) => {
  const dispatch = useDispatch();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const userId = useAppState(({ auth }) => auth.userId) as number;
  const [userLanguage, setUserLanguage] = useState<Language>(preferredLanguage);

  const { data, loading } = useQuery<LanguagePickerWrapperQuery, LanguagePickerWrapperQueryVariables>(LANGUAGES);

  const [updatePreferredLanguage] = useMutation<
    UpdatePreferredLanguageMutation,
    UpdatePreferredLanguageMutationVariables
  >(UPDATE_LANGUAGE);

  if (loading) {
    return emptyLanguagePicker;
  }

  const languages = data?.allLanguages || [];

  const handleLanguageChange = async (language: Language) => {
    setIsRefreshing(true);
    setUserLanguage(language);

    if (userId) {
      updatePreferredLanguage({ variables: { userId, code: language.code } });
    }

    dispatch(setLanguage(language));

    window.location.reload();
  };

  return (
    <>
      {isRefreshing && <Loading />}
      <LanguagePickerComponent
        languages={languages}
        preferredLanguage={userLanguage}
        onChange={handleLanguageChange}
      />
    </>
  );
};

const LanguagePicker: React.FC = () => {
  const preferredLanguage = useAppState(({ language }) => (language.preferredLanguage)) as Language;

  return (
    <UserLanguagePickerWrapper
      preferredLanguage={preferredLanguage}
    />
  );
};

export default LanguagePicker;
