import React, { useEffect, useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { debounce } from 'lodash';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import { lessonToWorkout } from 'app/pages/workouts';
import {
  LessonGroupWeekInfo,
  SelectDirection,
  WeeklyWorkoutListWrapperQuery,
  WeeklyWorkoutListWrapperQueryVariables,
} from 'app/types/graphql';

import {
  WeeklyWorkoutListWrapper as WORKOUTS,
} from 'app/ui/components/molecules/weekly-workout-list-wrapper/workouts.gql';

import Rail from 'app/ui/components/molecules/rail';
import ClassCard, { ClassCardContainer } from 'app/ui/components/atoms/class-card';
import Loading from 'app/ui/components/atoms/loading';
import Error from 'app/ui/components/atoms/error';
import Typography from 'app/ui/components/atoms/typography';

import { Workout } from 'app/ui/components/molecules/workout-list';
import { routes } from 'app/routes/constants';

type LessonGroup = {
  workouts: Workout[],
  weekInfo: LessonGroupWeekInfo,
};

const Week = styled.span`
  font-weight: bold;
`;

const RailTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const WeeklyWorkoutListWrapper: React.FC = () => {
  const first = 3;
  const intl = useIntl();
  const theme = useTheme();

  const [offset, setOffset] = useState(0);
  const [groups, setGroups] = useState<LessonGroup[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const queryVariables: WeeklyWorkoutListWrapperQueryVariables = {
    filters: {
      first,
      offset,
      orderBy: 'weekNumber',
      direction: SelectDirection.desc,
    },
  };

  const { data, loading, error } = useQuery<
    WeeklyWorkoutListWrapperQuery,
    WeeklyWorkoutListWrapperQueryVariables
  >(WORKOUTS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.lessonGroups?.nodes) {
      const newGroups = data.lessonGroups.nodes.map(({ lessons, weekInfo }) => {
        const workouts = lessons?.map((lesson) => lessonToWorkout(lesson)) || [];
        return { workouts, weekInfo } as LessonGroup;
      });
      setGroups((prevGroups) => [...prevGroups, ...newGroups]);
      setLoadingMore(false);
    }
  }, [data]);

  const loadMore = useCallback(() => {
    if (loadingMore) {
      return;
    }

    setLoadingMore(true);
    setOffset((prevOffset) => prevOffset + first);
  }, [first, loadingMore]);

  const debouncedHandleScroll = useCallback(
    debounce(() => {
      const scrollTop = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.offsetHeight;

      if (scrollTop >= documentHeight - 100) {
        loadMore();
      }
    }, 200),
    [loadMore]
  );

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [debouncedHandleScroll]);

  if (loading && groups.length === 0) {
    return <Loading />
  }

  if (error) {
    return <Error />;
  };

  const title = intl.formatMessage({ id: 'workoutLibrary.weekly' });
  const weekText = intl.formatMessage({ id: 'home.copy.week' });

  return (
    <>
      <Typography variant="h3">{title}</Typography>
      {groups.map(({ workouts, weekInfo }, index) => (
        <div key={index}>
          <Rail
            title={
              <RailTitle>
                <Typography variant="body1">
                  <Week>{weekText} {weekInfo?.number}</Week>
                </Typography>
                <Typography variant="body3" color={theme.colors.knop}>
                  {moment.utc(weekInfo?.from).format('DD/MM')}-
                  {moment.utc(weekInfo?.to).format('DD/MM YYYY')}
                </Typography>
              </RailTitle>
            }
          >
            {workouts.map((workout, idx) => (
              <ClassCardContainer key={idx}>
                <ClassCard
                  lessonId={workout.id}
                  srcSet={workout.srcSet}
                  title={workout.title}
                  to={routes.WORKOUT.replace(':id', workout.id)}
                  intensity={workout.intensity}
                  equipment={workout.equipment}
                  isSaved={workout.isSaved}
                  isCompleted={workout.isCompleted}
                />
              </ClassCardContainer>
            ))}
          </Rail>
        </div>
      ))}
    </>
  );
};

export default WeeklyWorkoutListWrapper;
