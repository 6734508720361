import React, { useState, useCallback, useRef, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import useServices from 'app/services';

import { simpleEmailRegex } from 'app/utils/email-validation';

import EntryLayout from 'app/ui/components/layouts/entry';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';

import FormFieldSet from 'app/ui/components/molecules/form-field-set';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
  }
`;

const TextFieldSetWrapper = styled.div`
  input {
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  text-decoration: underline;
`;

const ForgotPasswordPage = () => {
  const theme = useTheme();
  const services = useServices();
  const [requestedEmail, setRequestedEmail] = useState<string>();
  const { state: locationState } = useLocation();

  const intl = useIntl();

  const email = useRef<HTMLInputElement>(null);
  const defaultError = useMemo(
    () => ({
      email: null,
    }),
    [],
  );

  const [{ email: emailError }, setError] = useState<{
    email: string | null;
  }>(defaultError);

  const onSubmit = useCallback(async () => {
    try {
      setError(defaultError);
      if (email.current) {
        if (!simpleEmailRegex.test(email.current.value)) {
          setError({
            email: intl.formatMessage({ id: 'resetPassword.errors.invalidEmail' }),
          });
          return;
        }
        await services.password.requestResetPassword({
          email: email.current.value,
        });

        setRequestedEmail(email.current.value);
      }
    } catch (err) {
      setError({
        email: intl.formatMessage({ id: 'resetPassword.errors.errorOccurred' }),
      });
    }
  }, [services, defaultError]);

  const isLinkExpired = locationState?.expired;

  const resetPasswordHeader = isLinkExpired
    ? intl.formatMessage({ id: 'resetPassword.headers.linkExpired' })
    : intl.formatMessage({ id: 'resetPassword.headers.default' });

  const checkYourInboxHeader = intl.formatMessage({ id: 'resetPassword.headers.checkYourInbox' });

  const resetPasswordCopy = intl.formatMessage({ id: 'resetPassword.copy.default' });

  const emailLabel = intl.formatMessage({ id: 'resetPassword.labels.email' });

  const sendResetInstructionsLabel = intl.formatMessage({ id: 'resetPassword.actions.sendResetInstructions' });
  const signInLabel = intl.formatMessage({ id: 'resetPassword.actions.signIn' });

  return (
    <EntryLayout>
      {requestedEmail ? (
        <>
          <Typography variant="h4" color={theme.colors.white}>
            {checkYourInboxHeader}
          </Typography>
          <Typography variant="body2" color={theme.colors.lightGray}>
            <FormattedMessage
              id="resetPassword.copy.emailSent"
              values={{
                b: () => (
                  <b>{requestedEmail}</b>
                ),
              }}
            />
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" color={theme.colors.white}>
            {resetPasswordHeader}
          </Typography>
          <Typography variant="body2" color={theme.colors.lightGray}>
            {resetPasswordCopy}
          </Typography>
          <TextFieldSetWrapper>
            <FormFieldSet
              type="text"
              label={emailLabel}
              placeholder=""
              onChange={() => {}}
              inputRef={email}
              errorText={emailError}
            />
          </TextFieldSetWrapper>
          <ButtonWrapper>
            <Button label={sendResetInstructionsLabel} type="primary" onClick={onSubmit} />
          </ButtonWrapper>
          <LinkWrapper>
            <Link to="/sign-in">
              <Typography variant="body2" color={theme.colors.lightGray}>
                {signInLabel}
              </Typography>
            </Link>
          </LinkWrapper>
        </>
      )}
    </EntryLayout>
  );
};

export default ForgotPasswordPage;
