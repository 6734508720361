import { useEffect } from 'react';

const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} - ${prevTitle}`;
    return () => {
      document.title = prevTitle;
    }
  });
}

export default useTitle;
