import fetch from 'app/utils/requests/fetch';
import urlQueryParams from 'app/utils/requests/query-params';

type CreatePasswordServiceArgs = {
  url: string;
};

type RequestResetPasswordArgs = {
  email: string;
};

type CheckResetPasswordTokenArgs = {
  token: string;
};

type CheckResetPasswordTokenBody = {
  valid: boolean,
  token: string,
};

type ResetPasswordArgs = {
  token: string,
  password: string,
};

const createPasswordService = ({ url }: CreatePasswordServiceArgs) => {
  if (!url) {
    throw new Error('No url to password service supplied');
  }

  const requestResetPassword = async ({ email }: RequestResetPasswordArgs): Promise<void> => {
    const params = urlQueryParams({
      email,
    });

    await fetch(`${url}/token`, {
      body: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
  };

  const checkResetPasswordToken = async ({ token }: CheckResetPasswordTokenArgs): Promise<CheckResetPasswordTokenBody> => {
    const { body } = await fetch(`${url}/token/validate?token=${token}`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'GET',
    });

    return body;
  };

  const resetPassword = async({ token, password }: ResetPasswordArgs): Promise<any> => {
    const params = urlQueryParams({
      token,
      password
    });
    const { body } = await fetch(`${url}`, {
      body: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
    });

    return body;
  }

  return {
    requestResetPassword,
    checkResetPasswordToken,
    resetPassword,
  };
};

export type PasswordService = ReturnType<typeof createPasswordService>;

export default createPasswordService;
