import React, { RefObject } from 'react';

import Typography from 'app/ui/components/atoms/typography';
import TextField, { FieldState, TextFieldProps } from 'app/ui/components/atoms/text-field';

import { Fieldset } from 'app/ui/components/molecules/field-set-styled';

import { useTheme } from 'styled-components';
import { Theme } from 'app/providers/theme';

export interface TextFieldSetProps {
  label: string;
  onChange: (value: string) => void;
  type: TextFieldProps['type'];
  placeholder?: string;
  helperText?: string;
  inputRef?: RefObject<HTMLInputElement>;
  errorText?: string | null;
  value?: string;
  disabled?: boolean,
  readonly?: boolean,
  labelColor?: string;
  onTypeToggle?: () => void,
  icon?: JSX.Element;
}

type ResolveFieldStateArgs = {
  errorText?: string | null;
  disabled?: boolean;
  readonly?: boolean;
}

const resolveFieldState = ({ errorText, disabled, readonly }: ResolveFieldStateArgs): FieldState => {
  if (errorText) {
    return 'error';
  }

  if (disabled) {
    return 'disabled';
  }

  if (readonly) {
    return 'readonly';
  }

  return 'default';
}

export const TextFieldSet: React.FC<TextFieldSetProps> = ({
  label,
  onChange,
  type,
  placeholder,
  helperText,
  inputRef,
  errorText,
  value,
  disabled,
  readonly,
  labelColor,
  onTypeToggle,
  icon,
}) => {
  const theme: Theme = useTheme();
  return (
    <Fieldset>
      <Typography color={labelColor} variant="body2">{label}</Typography>
      <TextField
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        inputRef={inputRef}
        fieldstate={resolveFieldState({ errorText, disabled, readonly })}
        value={value}
        onTypeToggle={onTypeToggle}
        icon={icon}
      />
      {errorText && <Typography variant="body2" color={theme.colors.firebreathingRed} htmlContent={errorText} />}
      {helperText && <Typography variant="body2" color={theme.colors.lightGray} htmlContent={helperText} />}
    </Fieldset>
  );
};
