import React, { RefObject, ChangeEvent, FocusEvent } from 'react';
import styled from 'styled-components';

export type FieldState = 'error' | 'disabled' | 'readonly' | 'default';

export interface TextFieldProps {
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  fieldstate?: FieldState;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';
  value?: string | number;
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  inputRef?: RefObject<HTMLInputElement>;
  onTypeToggle?: () => void,
}

const IconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: auto;
  overflow: hidden;
  min-width: 12.5rem;
  margin: 0.25rem 0 0 0;
`;

const StyledInput = styled.input<TextFieldProps>`
  ${({ theme: { spacing, typography, colors, media }, fieldstate, iconPosition }) => {
    return `
      display: flex;
      font-family: ${typography.poppins};
      font-weight: ${typography.light};
      outline: none;
      font-size: ${typography.small};
      line-height: ${typography.lineHeight};
      margin: 0.5rem 0;
      border: .0625rem solid ${colors.black};
      padding-top: ${spacing.small};
      padding-bottom: ${spacing.small};
      padding-left: ${iconPosition === 'left' ? spacing.superhumongous : spacing.medium};
      padding-right: ${iconPosition === 'right' ? spacing.superhumongous : spacing.medium};
      border-radius: ${spacing.xsmall};

      &::placeholder {
        color: ${colors.knop};
      }

      &:focus {
        border: .125rem solid ${colors.focoltone};
      }

      &:active {
        border: .0625rem solid ${colors.pascol};
      }

      // Style for error state
      ${
        fieldstate === 'error' &&
        `
        color: ${colors.firebreathingRed};
        border: .125rem solid ${colors.firebreathingRed};
      `
      }

      // Style for disabled state
      ${
        fieldstate === 'disabled' &&
        `
        color: ${colors.knop};
      `
      }

      ${
        fieldstate === 'readonly' &&
        `
        cursor: not-allowed;
        background: ${colors.veryGray};
        color: ${colors.blackSwan};
      `
      }

      ${media.medium(
        () => `
        line-height: ${spacing.medium};
      `,
      )}

      ${media.large(
        () => `
        line-height: ${spacing.large};
      `,
      )}
    `;
  }}
`;

const Icon = styled.div<TextFieldProps>`
  ${({ theme: { spacing }, iconPosition }) => {
    return `
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 ${spacing.small};
      ${
        iconPosition === 'left'
          ? `
          left: 0;
        `
          : `
          right: 0;
        `
      }

      img {
        max-width: 12.5rem;
      }
    `;
  }}
`;

export const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  fieldstate = 'default',
  onChange,
  onFocus,
  onBlur,
  type = 'text',
  value,
  icon,
  iconPosition,
  inputRef,
  onTypeToggle,
}) => {
  return (
    <IconWrapper>
      {icon && <Icon iconPosition={iconPosition} onClick={onTypeToggle}>{icon}</Icon>}
      <StyledInput
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        fieldstate={fieldstate}
        disabled={fieldstate === 'disabled'}
        readOnly={fieldstate === 'readonly'}
        type={type}
        value={value}
        iconPosition={iconPosition}
        ref={inputRef}
      />
    </IconWrapper>
  );
};

export default TextField;
