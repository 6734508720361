import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { medium } } }) => `
    width: ${medium};
    height: ${medium};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const ChevronRight = ({ className = '', color }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.66699 3.33268L10.0003 7.99935L4.66699 12.666L5.33366 13.3327C6.75366 12.206 10.1863 9.05868 11.3337 7.99935C10.4818 7.22468 11.3129 7.98602 5.33366 2.66602L4.66699 3.33268Z" fill={color} stroke={color}/>
  </Svg>
);

export default ChevronRight;
