import React from 'react';
import styled from 'styled-components';

import LoadingMolecule from 'app/ui/components/molecules/loading';

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.black};
  z-index: 9999;
`;

const LoadingWithOverlay: React.FC = () => (
  <OverlayWrapper>
    <LoadingMolecule />
  </OverlayWrapper>
);

export default LoadingWithOverlay;
