import cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import memoize from 'lodash/memoize';

import { Signin, RefreshTokens, AuthActionTypes } from 'actions/auth';
import createReducer from 'utils/create-reducer';
import { ActionCreator, Handler } from 'actions';

type AuthHandler<Action extends ActionCreator> = Handler<AuthState, Action>;

type BaseAuthState = {
  authorization: string | null;
  expires: Date | null;
  signedIn: boolean;
  refreshToken: string | null;
  userId: number | null;
};

export type AuthState = BaseAuthState & {
  initialized: boolean;
};

const emptyState: BaseAuthState = {
  authorization: null,
  expires: null,
  signedIn: false,
  refreshToken: null,
  userId: null,
};

const AUTH_COOKIE_NAME = 'hyrox_authorization';
const AUTH_LS_EXPIRES = 'hyrox_authorization_expiry';
const REFRESH_LS_NAME = 'hyrox_authorization_refresh_token';

const setState: (state: AuthState) => void = ({ authorization, expires, refreshToken }: AuthState) => {
  if (authorization) {
    cookies.set(AUTH_COOKIE_NAME, authorization, { domain: window.location.hostname });
  }
  if (expires) {
    localStorage.setItem(AUTH_LS_EXPIRES, String(expires));
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_LS_NAME, refreshToken);
  }
};

type DecodedAuthToken = {
  user: {
    id: string;
  };
};

export const extractUserIdFromToken = memoize((accessToken: string) =>
  parseInt(jwtDecode<DecodedAuthToken>(accessToken).user.id, 10),
);

const tokenTypeRegex = /^[^ ]* /;
export const extractUserIdFromAuth = memoize((authorization: string) =>
  extractUserIdFromToken(authorization.replace(tokenTypeRegex, '')),
);

const clearState: () => void = () => {
  cookies.remove(AUTH_COOKIE_NAME);
  localStorage.removeItem(AUTH_LS_EXPIRES);
  localStorage.removeItem(REFRESH_LS_NAME);
};

export const getPersistedState = (): BaseAuthState => {
  const authorization = cookies.get(AUTH_COOKIE_NAME) || null;
  const expires = localStorage.getItem(AUTH_LS_EXPIRES);
  const refreshToken = localStorage.getItem(REFRESH_LS_NAME);

  if (!refreshToken) {
    clearState();
    return emptyState;
  }

  return {
    authorization,
    expires: expires ? new Date(expires) : null,
    signedIn: true,
    refreshToken,
    userId: authorization ? extractUserIdFromAuth(authorization) : null,
  };
};

const updateTokens: () => AuthHandler<Signin | RefreshTokens> =
  () =>
  (_state, payload) => {
    const { accessToken, accessTokenExpiresAt, refreshToken } = payload;

    const userId = accessToken ? extractUserIdFromToken(accessToken) : null;

    const newState = {
      authorization: `Bearer ${accessToken}`,
      expires: new Date(accessTokenExpiresAt),
      signedIn: true,
      refreshToken,
      initialized: true,
      userId,
    };

    setState(newState);
    return newState;
  };

const handlers = {
  [AuthActionTypes.SIGNIN]: updateTokens(),
  [AuthActionTypes.REFRESH_TOKENS]: updateTokens(),
  [AuthActionTypes.SIGNOUT]: (): AuthState => {
    clearState();
    return {
      ...emptyState,
      initialized: true,
    };
  },
};

const persistedState = getPersistedState();
export const initialAuthState = {
  ...persistedState,
  // If we're logged in we will check the token still works on load, while we perform this check "initialized = false"
  // If we're not logged in there is no token for us to check so "initialized = true" immediately
  initialized: !persistedState.signedIn,
};
export const authReducer = createReducer<AuthState>(handlers);
