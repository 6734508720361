import React from 'react';
import styled from 'styled-components';

import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';
import LessonSectionBreakdown from 'app/ui/components/molecules/whiteboard-slide/section-breakdown';

type LessonOverviewProps = {
  sectionsData: ContentData[];
};

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};

  ${({ theme: { media } }) => `${media.medium(() => `
        flex-direction: row;
    `)}
  `};
`;

const LessonOverview: React.FC<LessonOverviewProps> = ({ sectionsData }) => (
  <SectionsContainer>
    {sectionsData.map((sectionData) => (
      <LessonSectionBreakdown
        sectionData={sectionData}
        isOverviewMode={true}
      />
    ))}
  </SectionsContainer>
);

export default LessonOverview;
