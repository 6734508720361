import { ApolloClient, InMemoryCache, HttpLink, Observable } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

import { AuthService, TokenPayload } from 'app/services/auth';

import { getPersistedState } from 'state/auth';

type GetClientArgs = {
  uri: string,
  authService: AuthService,
  onTokenRefresh: (payload: TokenPayload) => void;
  onTokenRefreshError: (error: Error) => void;
};

type AuthHeaders = {
  authorization: string | null;
};
type RefreshRequest = Promise<AuthHeaders>;

type GetAuthHeadersArgs = {
  refreshToken: string | null,
  authorization: string | null,
};

export default ({ uri, authService, onTokenRefresh, onTokenRefreshError }: GetClientArgs) => {
  const httpLink = new HttpLink({ uri });
  const cache = new InMemoryCache();

  const getAuthHeaders = async ({ refreshToken, authorization }: GetAuthHeadersArgs): RefreshRequest => {
    // If we have an auth header stored, use it
    if (authorization) {
      return { authorization };
    }

    // If there's no auth header, we need to get one - but if we don't have a refresh token then we're fucked
    if (!refreshToken) {
      onTokenRefreshError(new Error('Cannot obtain new authorization without a refresh token'));

      return { authorization: null };
    }

    return authService.refreshToken({ refreshToken })
      .then((response) => {
        // logger.debug('refreshing tokens');

        // Store the new tokens
        onTokenRefresh(response);

        // Retrieve the new tokens in the right format (we could also reformat here but :shrug:)
        // This also requires that onTokenRefresh complete it's side effect before proceeding
        const { authorization: newAuthorization } = getPersistedState();

        return { authorization: newAuthorization };
      })
      .catch((refreshError) => {
        onTokenRefreshError(refreshError);

        return { authorization: null };
      });
  };

  const getContext = async ({ headers = {}, ...context }) => {
    const { authorization, refreshToken } = getPersistedState();
    const authHeaders = await getAuthHeaders({ authorization, refreshToken });
    return {
      ...context,
      headers: {
        ...headers,
        ...(authHeaders?.authorization ? authHeaders  : {}),
      },
    };
  };

  const authLink = setContext(async (_, { headers }) => {
    const { authorization, refreshToken } = getPersistedState();
    const authHeaders = await getAuthHeaders({ authorization, refreshToken });
    return {
      headers: {
        ...headers,
        ...(authHeaders?.authorization ? authHeaders : {}),
      },
    };
  });

  const refreshTokenLink = onError(({ operation, graphQLErrors = [], forward }) => {
    const { refreshToken } = getPersistedState();
    const authError = graphQLErrors.find(({ extensions }) => extensions?.code === 'UNAUTHENTICATED');

    if (authError && refreshToken) {
      return new Observable((observer) => {
        getAuthHeaders({ refreshToken, authorization: null })
          .then(async () => {
            const oldContext = operation.getContext();
            const newContext = await getContext(oldContext);
            operation.setContext(newContext);

            // Retry the request
            forward(operation).subscribe(observer);
          })
          .catch((refreshError: Error) => {
            observer.error(refreshError);
          });
      });
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(refreshTokenLink).concat(httpLink),
    cache,
  });

  return {
    client,
  };
};
