export enum AuthActionTypes {
  SIGNIN = 'AUTH/SIGNIN',
  REFRESH_TOKENS = 'AUTH/REFRESH_TOKENS',
  SIGNOUT = 'AUTH/SIGNOUT',
}

type TokenPayload = {
  accessToken: string;
  accessTokenExpiresAt: number;
  refreshToken: string;
  refreshTokenExpiresAt: string;
};

export const signin = (payload: TokenPayload) => ({
  type: AuthActionTypes.SIGNIN,
  payload,
});
export type Signin = typeof signin;

export const refreshTokens = (payload: TokenPayload) => ({
  type: AuthActionTypes.REFRESH_TOKENS,
  payload,
});
export type RefreshTokens = typeof refreshTokens;

export const signout = () => ({
  type: AuthActionTypes.SIGNOUT,
});
export type signout = typeof signout;

export default { signin, refreshTokens, signout };
