import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';

import ErrorIcon from 'app/assets/error-icon.svg';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 38rem;
  gap: 2rem;

  h3 {
    text-align: center;
    justify-content: center;
    line-height: 1em;
  }

  img {
    max-width: 3.75rem;

    ${({ theme: { media } }) => `
    ${media.large(
      () => `
      max-width: 5.5rem;
      `,
    )}

    `}
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 6rem;

  ${({ theme: { media } }) => `
    ${media.small(
      () => `
      padding: 0;
      `,
    )}
  `}

  button {
    width: 100%;
    justify-content: center;
  }
`;

const Error: React.FC = () => {
  const theme = useTheme();
  return (
    <ErrorWrapper>
      <img src={ErrorIcon} alt="Error Icon" />
      <Typography variant="h3">Oops! Something went wrong...</Typography>
      <Typography variant="body1" color={theme.colors.knop}>
        It looks like something didn’t go as planned. We’re working to fix and will be up and running soon.
      </Typography>
      <ButtonWrapper>
        <Button
          type="primary"
          label="Back"
          onClick={() => {
            console.log('Back clicked');
          }}
        />
      </ButtonWrapper>
    </ErrorWrapper>
  );
};

export default Error;
