import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Skierg = ({ color, className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.38253 2.62122C7.51294 2.23826 7.9291 2.03353 8.31206 2.16393C8.47935 2.2209 8.61263 2.3324 8.69882 2.47184L15.3012 2.47184C15.3874 2.3324 15.5207 2.2209 15.6879 2.16393C16.0709 2.03353 16.4871 2.23826 16.6175 2.62122L17.5057 5.22958C17.6361 5.61254 17.4314 6.0287 17.0484 6.15911C16.6655 6.28952 16.2493 6.08478 16.1189 5.70183L15.5007 3.88646L13.6796 12.1502H14.5322C15.5513 12.1502 16.4074 12.9165 16.52 13.9294L17.1825 19.892C17.6476 19.9777 18 20.3854 18 20.8754V21.8754L17.4029 21.8754H16.598H7.40209H6.59717L6 21.8754V20.8754C6 20.3854 6.35238 19.9777 6.81755 19.892L7.48006 13.9294C7.5926 12.9165 8.44873 12.1502 9.46782 12.1502H10.228L8.44322 4.05115L7.88112 5.70183C7.75071 6.08478 7.33455 6.28952 6.95159 6.15911C6.56863 6.0287 6.3639 5.61254 6.49431 5.22958L7.38253 2.62122ZM12.1496 12.1502H11.829L8.84238 3.27184L15.0703 3.27184L12.1496 12.1502ZM10.4043 12.9502H9.46782C8.85637 12.9502 8.34269 13.41 8.27516 14.0177L7.62431 19.8754H10.2527C9.32523 19.2932 8.70862 18.2614 8.70862 17.0855C8.70862 15.7805 9.46812 14.6529 10.5692 14.1205H10.5646V13.6775L10.4043 12.9502ZM16.3757 19.8754L15.7249 14.0177C15.6574 13.41 15.1437 12.9502 14.5322 12.9502H13.5033L13.4355 13.258V14.1205H13.4308C14.5319 14.6529 15.2914 15.7805 15.2914 17.0855C15.2914 18.2614 14.6748 19.2932 13.7473 19.8754H16.3757ZM12 18.7798C12.9357 18.7798 13.6943 18.0212 13.6943 17.0855C13.6943 16.1498 12.9357 15.3912 12 15.3912C11.0643 15.3912 10.3057 16.1498 10.3057 17.0855C10.3057 18.0212 11.0643 18.7798 12 18.7798ZM12 19.2798C13.2119 19.2798 14.1943 18.2974 14.1943 17.0855C14.1943 15.8737 13.2119 14.8912 12 14.8912C10.7881 14.8912 9.80574 15.8737 9.80574 17.0855C9.80574 18.2974 10.7881 19.2798 12 19.2798Z" fill={color}/>
  </Svg>
);

export default Skierg;
