import React from 'react';
import styled from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import PillElement from 'app/ui/components/atoms/pill';

import { Fieldset } from 'app/ui/components/molecules/field-set-styled';

export type PillData = {
  id: number | string;
  name: string;
}

export type Pill = PillData & {
  isChecked: boolean;
};

export type Pills = Pill[];

export type PillOnChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => void;

export interface PillFieldSetProps {
  pills: Pills;
  label: string;
  onChange: PillOnChangeEvent;
  disabled?: boolean;
}

const PillFieldset = styled(Fieldset)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 1rem 0;
  ${({ theme: { spacing, media } }) => `
    ${media.medium(
      () => `
        gap: ${spacing.xsmall};
      `,
    )}
  `}
`;

export const PillFieldSet: React.FC<PillFieldSetProps> = ({ pills, label, onChange, disabled = false }) => {
  return (
    <Fieldset>
      <Typography variant="body2">{label}</Typography>
      <PillFieldset>
        {pills.map(
          ({ id, name, isChecked }) =>
            id &&
            name && (
              <PillElement
                id={id}
                name={name}
                isChecked={isChecked}
                disabled={disabled}
                onChange={(e) => !disabled && onChange(e)}
                key={id + '-' + name}
              />
            ),
        )}
      </PillFieldset>
    </Fieldset>
  );
};
