import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  color: string,
  className?: string,
};

const Bench = ({ color, className = '' }: Props) => (
  <Svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.18199 3.43934C3.5962 2.85355 2.64645 2.85355 2.06067 3.43934C1.47488 4.02513 1.47488 4.97487 2.06067 5.56066L8.76232 12.2623L7.4348 14.5616H5.80924V14.5693L2.51013 20.2835H2C1.44772 20.2835 1 20.7312 1 21.2835V21.7835H4.87539V21.2835C4.87539 20.8483 4.59736 20.478 4.20923 20.3406L6.67967 16.0616L13.8206 16.0616L19.0775 20.5475C18.8789 20.7302 18.7544 20.9923 18.7544 21.2835V21.7835H22.6298V21.2835C22.6298 20.7312 22.1821 20.2835 21.6298 20.2835H21.0789L17.0067 16.8085H21.1513C21.9798 16.8085 22.6513 16.1369 22.6513 15.3085C22.6513 14.4801 21.9798 13.8085 21.1513 13.8085H16.9175C16.361 13.8085 15.8753 14.1116 15.6164 14.5616H14.3736L14.2483 14.4548C14.3443 13.9788 14.2078 13.4652 13.8387 13.0961L4.18199 3.43934ZM11.0617 14.5616L9.8604 13.3604L9.16685 14.5616H11.0617Z" fill={color}/>
  </Svg>
);

export default Bench;
