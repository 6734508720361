import { createGlobalStyle } from 'styled-components';
import normalize from 'app/normalise.css';

import 'rc-slider/assets/index.css';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  // other global styles...
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  html {
    font-size: 16px;

    ${({ theme: { media } }) => {
      return `
        ${media.xl(
          ({ scaleForHighRes }) => `
            font-size: ${scaleForHighRes('16px')};
            `,
        )}
      `;
    }}

  }
  body {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    background: ${({ theme: { colors } }) => colors.black};
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

export default GlobalStyle;
