import React, { useCallback } from 'react';

import { Pills, Pill } from 'app/ui/components/molecules/pill-field-set';

export type CreateAllPillProps = {
  elementName: string,
  selectedElements: string[] | null,
  name?: string,
};

export type CreatePillsFromData = {
  prependPills: Pills,
  data: any[],
  selectedElements: string[] | null,
};

export const createAllPill = ({ elementName, selectedElements, name = 'All' }: CreateAllPillProps): Pill => ({
  id: `all-${elementName}`,
  name,
  isChecked: !selectedElements || selectedElements.includes(`all-${elementName}`)
});

export const createPillsFromData = ({ prependPills, data, selectedElements }: CreatePillsFromData): Pills => ([
  ...prependPills,
  ...(data?.map((element) => ({
    id: element?.id || element,
    name: element?.name || `${element}`,
    isChecked: !!selectedElements && selectedElements.includes(element?.id || element),
  })) || []),
]);

type SetFiltersFunction = React.Dispatch<React.SetStateAction<Pills>>;

type UseMultipleFilterChangeHandlerProps = {
  setFilters: SetFiltersFunction,
  setSelected: React.Dispatch<React.SetStateAction<string[]>>,
  allOptionName: string,
};

export const useFilterChangeHandler = (
  setFilters: SetFiltersFunction,
  setSelected: React.Dispatch<React.SetStateAction<string | null>>,
) => useCallback(
  (value: string) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter) => {
        const isChecked = filter.id.toString() === value;
        if (isChecked) {
          setSelected(filter.id.toString());
        }
        return {
          ...filter,
          isChecked,
        };
      })
    );
  },
  [setFilters, setSelected],
);

export const useMultipleFilterChangeHandler = ({
  setSelected,
  setFilters,
  allOptionName,
}: UseMultipleFilterChangeHandlerProps) => useCallback(
  (value: string) => {
    setSelected((prevSelected) => {
      const newSelected = value === allOptionName
        ? (prevSelected.includes(value) ? [] : [value])
        : (prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected.filter((item) => item !== allOptionName), value]);

      setFilters((currentFilters) =>
        currentFilters.map((filter) => ({
          ...filter,
          isChecked: newSelected.includes(filter.id.toString())
        }))
      );

      return newSelected;
    });
  },
  [setFilters, setSelected]
);
