import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};
  `}
`;

type Props = {
  className?: string,
};

const TripletDark = ({ className = '' }: Props) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM13.3999 15.6331C13.3999 14.0968 14.654 12.8502 16.2003 12.8502C17.7466 12.8502 19 14.0968 19 15.6331C19 17.1701 17.7466 18.4167 16.2003 18.4167C14.654 18.4167 13.3999 17.1701 13.3999 15.6331ZM5 15.6331C5 14.0968 6.25413 12.8502 7.80044 12.8502C9.34674 12.8502 10.6001 14.0968 10.6001 15.6331C10.6001 17.1701 9.34674 18.4167 7.80044 18.4167C6.25413 18.4167 5 17.1701 5 15.6331ZM12.0004 11.1491C13.5467 11.1491 14.8001 9.90322 14.8001 8.36621C14.8001 6.8292 13.5467 5.58333 12.0004 5.58333C10.4541 5.58333 9.19993 6.8292 9.19993 8.36621C9.19993 9.90322 10.4541 11.1491 12.0004 11.1491Z" fill="black"/>
  </Svg>
);

export default TripletDark;
