type Action = {
  type: string,
  payload?: any,
};

function createReducer<State>(
  handlers: Record<string, (state: State, payload: any) => State>,
) {
  return (state: State, action: Action) => {
    const handler = handlers[action.type];
    return handler ? handler(state, action.payload) : state;
  };
}

export default createReducer;
