import { flatten } from 'flat';

import EnglishLocale from 'app/locales/en.json';
import PolishLocale from 'app/locales/pl.json';
import SpanishLocale from 'app/locales/es.json';
import ItalianLocale from 'app/locales/it.json';
import GermanLocale from 'app/locales/de.json';
import FrenchLocale from 'app/locales/fr.json';
import JapaneseLocale from 'app/locales/ja.json';
import KoreanLocale from 'app/locales/ko.json';
import PortugeseLocale from 'app/locales/pt.json';
import SimplifiedChineseLocale from 'app/locales/zh.json';
import TraditionalChineseLocale from 'app/locales/zh-tw.json';

type AppLocales = {
  [key: string]: any,
};

export const locales: AppLocales = {
  en: flatten(EnglishLocale),
  es: flatten(SpanishLocale),
  pl: flatten(PolishLocale),
  it: flatten(ItalianLocale),
  de: flatten(GermanLocale),
  fr: flatten(FrenchLocale),
  ja: flatten(JapaneseLocale),
  ko: flatten(KoreanLocale),
  pt: flatten(PortugeseLocale),
  zh: flatten(SimplifiedChineseLocale),
  'zh-tw': flatten(TraditionalChineseLocale),
};
