export enum OnboardActionTypes {
  GYM_TYPE = 'ONBOARD/GYM_TYPE',
  GYM_EQUIPMENT = 'ONBOARD/GYM_EQUIPMENT',
  ONBOARD_STATUS_LOADED = 'ONBOARD/LOADED',
  ONBOARD_STATUS_RESET = 'ONBOARD/RESET',
}

export const onboardGymType = () => ({
  type: OnboardActionTypes.GYM_TYPE,
});

export type OnboardGymType = typeof onboardGymType;

export const onboardGymEquipment = () => ({
  type: OnboardActionTypes.GYM_EQUIPMENT,
});

export type OnboardGymEquipment = typeof onboardGymEquipment;

export const onboardStatusLoaded = () => ({
  type: OnboardActionTypes.ONBOARD_STATUS_LOADED,
});

export type OnboardStatusLoaded = typeof onboardStatusLoaded;

export const onboardStatusReset = () => ({
  type: OnboardActionTypes.ONBOARD_STATUS_RESET,
});

export type OnboardStatusReset = typeof onboardStatusReset;

export default { onboardGymType, onboardGymEquipment };
