import React from 'react';

import styled from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';
import { theme } from 'app/providers/theme';

export type Props = {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
  textColor?: string;
};

const Svg = styled.svg<Props>`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  fill: ${({ fill }) => fill};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const Text = styled(Typography)`
  font-size: 0.5rem;
`;

const PoweredByFiit: React.FC<Props> = ({
  width,
  height,
  className,
  fill = theme.colors.white,
  textColor = theme.colors.lightGray,
}) => {
  return (
    <Wrapper>
      <Text color={textColor} variant="caption">
        Powered by
      </Text>
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0rem"
        y="0rem"
        viewBox="0 0 246.78 123.04"
        xmlSpace="preserve"
        width={width}
        height={height}
        className={className}
      >
        <title>FIIT LOGO</title>
        <path
          fill={fill}
          d="M935.69,479.25a10.79,10.79,0,1,0,7.79,7.79A10.78,10.78,0,0,0,935.69,479.25Z"
          transform="translate(-836.88 -478.9)"
        />
        <path
          fill={fill}
          d="M888.18,553.43h-33.1a.85.85,0,0,1-.85-.85V533a.85.85,0,0,1,.85-.86h42.57a.85.85,0,0,0,.85-.85V518a.85.85,0,0,
        0-.85-.85H837.73a.85.85,0,0,0-.85.85v83.14a.85.85,0,0,0,.85.85h15.64a.85.85,0,0,0,.86-.85V569.35a.85.85,0,0,
        1,.85-.85h33.1a.85.85,0,0,0,
        .85-.86V554.29A.85.85,0,0,0,888.18,553.43Z"
          transform="translate(-836.88 -478.9)"
        />
        <rect fill={fill} x="87.02" y="38.19" width="17.35" height="84.85" rx="0.85" />
        <rect fill={fill} x="136.51" y="38.19" width="17.35" height="84.85" rx="0.85" />
        <path
          fill={fill}
          d="M1082.8,517.1H1017a.86.86,0,0,0-.86.85v13.84a.86.86,0,0,0,.86.85h23.44a.85.85,0,0,1,.85.86v67.59a.85.85,0,0,
        0,.85.85h15.53a.85.85,0,0,0,.85-.85V533.5a.85.85,0,0,1,.85-.86h23.44a.85.85,0,0,0,.86-.85V518A.85.85,0,0,0,
        1082.8,517.1Z"
          transform="translate(-836.88 -478.9)"
        />
        <path
          fill={fill}
          d="M984.71,479.25a10.79,10.79,0,1,0,7.8,7.79A10.79,10.79,0,0,0,984.71,479.25Z"
          transform="translate(-836.88 -478.9)"
        />
      </Svg>
    </Wrapper>
  );
};

export default PoweredByFiit;
