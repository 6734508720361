import React from 'react';
import styled from 'styled-components';
import Typography from 'app/ui/components/atoms/typography';

export type Type = 'text' | 'link' | 'password';

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 0.03125rem solid ${({ theme }) => theme.colors.blackSwan};
  border-bottom: 0.03125rem solid ${({ theme }) => theme.colors.blackSwan};
  padding: 1.25rem 0;
`;

const Label = styled.div`
  font-weight: 600;
  width: 17%;
  min-width: 11.25rem;
  line-height: 1.5rem;
  word-wrap: break-word;

  ${({ theme: { media } }) => {
    return `
        ${media.small(
          () => `
            text-wrap: wrap;
            width: 35%;
            padding-right: 1%;
            min-width: 8.4375rem;
            `,
        )} 
        `;
  }}
`;

const Value = styled.div<{ type: Type }>`
  font-weight: 400;
  line-height: 1.5rem;
  width: 83%;
  word-wrap: break-word;

  ${({ theme: { media } }) => {
    return `
        ${media.small(
          () => `
            width: 60%;
            `,
        )} 
    `;
  }}

  ${({ type }) => {
    if (type === 'link') {
      return `
        cursor: pointer;
      `;
    }
  }}
`;

export interface Props {
  label: string;
  value: string;
  type: Type;
}

const ViewProfileField = ({ label, value, type }: Props) => {
  return (
    <FieldGroup>
      <Label>
        <Typography variant="label">{label}</Typography>
      </Label>
      <Value type={type}>
        <Typography variant="body2">{value}</Typography>
      </Value>
    </FieldGroup>
  );
};

export default ViewProfileField;
