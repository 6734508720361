import { Fragment, ReactNode, createElement } from 'react';
import { useIntl } from 'react-intl';

import AMRAPDark from 'app/ui/components/atoms/icons/section-formats/amrap/dark';
import ChipperDark from 'app/ui/components/atoms/icons/section-formats/chipper/dark';
import CoupletDark from 'app/ui/components/atoms/icons/section-formats/couplet/dark';
import IGOUYGODark from 'app/ui/components/atoms/icons/section-formats/i-go-you-go/dark';
import IntervalsDark from 'app/ui/components/atoms/icons/section-formats/intervals/dark';
import PartnerWorkoutDark from 'app/ui/components/atoms/icons/section-formats/partner/dark';
import TabataDark from 'app/ui/components/atoms/icons/section-formats/tabata/dark';
import DoubleTabataDark from 'app/ui/components/atoms/icons/section-formats/tabata-double/dark';
import TO2MDark from 'app/ui/components/atoms/icons/section-formats/to2m/dark';
import TO3MDark from 'app/ui/components/atoms/icons/section-formats/to3m/dark';
import TOTMDark from 'app/ui/components/atoms/icons/section-formats/totm/dark';
import TripletDark from 'app/ui/components/atoms/icons/section-formats/triplet/dark';
import RotationalDark from 'app/ui/components/atoms/icons/section-formats/rotation/dark';

import { LessonSectionFormat } from 'app/types/graphql';

export enum OtherSectionIcons {
  ROTATIONAL,
}

type AvailableSectionFormats = LessonSectionFormat | OtherSectionIcons;

type SectionFormatIconDetails = {
  icon: ReactNode,
  name: string,
  description: string,
};

const useSectionFormatDetails = (format: AvailableSectionFormats): SectionFormatIconDetails => {
  const intl = useIntl();

  switch (format) {
    case LessonSectionFormat.AMRAP:
      return {
        icon: createElement(AMRAPDark),
        name: intl.formatMessage({ id: 'sectionFormats.amrap.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.amrap.description' }),
      };
    case LessonSectionFormat.CHIPPER:
      return {
        icon: createElement(ChipperDark),
        name: intl.formatMessage({ id: 'sectionFormats.chipper.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.chipper.description' }),
      };
    case LessonSectionFormat.COUPLET:
      return {
        icon: createElement(CoupletDark),
        name: intl.formatMessage({ id: 'sectionFormats.couplet.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.couplet.description' }),
      };
    case LessonSectionFormat.I_GO_YOU_GO:
      return {
        icon: createElement(IGOUYGODark),
        name: intl.formatMessage({ id: 'sectionFormats.iGoYouGo.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.iGoYouGo.description' }),
      };
    case LessonSectionFormat.INTERVALS:
      return {
        icon: createElement(IntervalsDark),
        name: intl.formatMessage({ id: 'sectionFormats.intervals.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.intervals.description' }),
      };
    case LessonSectionFormat.PARTNER_WORKOUT:
      return {
        icon: createElement(PartnerWorkoutDark),
        name: intl.formatMessage({ id: 'sectionFormats.partnerWorkout.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.partnerWorkout.description' }),
      };
    case LessonSectionFormat.PARTNER_CHIPPER:
      return {
        icon: createElement('div', { style: { display: 'flex', gap: '0.25rem' } },
          createElement(PartnerWorkoutDark),
          createElement(ChipperDark),
        ),
        name: intl.formatMessage({ id: 'sectionFormats.partnerChipper.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.partnerChipper.description' }),
      };
    case LessonSectionFormat.TABATA:
      return {
        icon: createElement(TabataDark),
        name: intl.formatMessage({ id: 'sectionFormats.tabata.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.tabata.description' }),
      };
    case LessonSectionFormat.DOUBLE_TABATA:
      return {
        icon: createElement(DoubleTabataDark),
        name: intl.formatMessage({ id: 'sectionFormats.doubleTabata.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.doubleTabata.description' }),
      };
    case LessonSectionFormat.TO2M:
      return {
        icon: createElement(TO2MDark),
        name: intl.formatMessage({ id: 'sectionFormats.to2m.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.to2m.description' }),
      };
    case LessonSectionFormat.TO3M:
      return {
        icon: createElement(TO3MDark),
        name: intl.formatMessage({ id: 'sectionFormats.to3m.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.to3m.description' }),
      };
    case LessonSectionFormat.TOTM:
      return {
        icon: createElement(TOTMDark),
        name: intl.formatMessage({ id: 'sectionFormats.totm.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.totm.description' }),
      };
    case LessonSectionFormat.TRIPLET:
      return {
        icon: createElement(TripletDark),
        name: intl.formatMessage({ id: 'sectionFormats.triplet.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.triplet.description' }),
      };
    case OtherSectionIcons.ROTATIONAL:
      return {
        icon: createElement(RotationalDark),
        name: intl.formatMessage({ id: 'sectionFormats.rotational.name' }),
        description: intl.formatMessage({ id: 'sectionFormats.rotational.description' }),
      };
    default: 
      return {
        icon: createElement(Fragment),
        name: '',
        description: '',
      };
  }
};

export default useSectionFormatDetails;
