import React from 'react';
import styled from 'styled-components';

const DividerWrapper = styled.span`
  margin: 0 0.15rem;
  display: flex;
  align-items: center;
`;

const DividerDot = styled.span<{color?: string}>`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  ${({ color, theme }) => `background-color: ${color ? color : theme.colors.white}`};
`;

type DividerProps = {
  color?: string,
};

const DotDivider = ({ color }: DividerProps) => (
  <DividerWrapper>
    <DividerDot color={color} />
  </DividerWrapper>
);

export default DotDivider;