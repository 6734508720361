import React from 'react';

import GenericPage from 'app/ui/components/layouts/generic-page';
import WorkoutListController from 'app/ui/components/molecules/workout-list-controller';

import { lengthFromDurationSeconds } from 'app/utils/duration-handler';
import { Equipment, Lesson } from 'app/types/graphql';

export type LessonToWorkoutDetails = {
  id: string,
  srcSet: string,
  title: string,
  additionalInfo: string,
  intensity: string,
  equipment?: Equipment[] | null,
  isSaved: boolean,
  isCompleted: boolean,
};

export const lessonToWorkout = (lesson: any): LessonToWorkoutDetails => ({
  id: lesson.id,
  srcSet: lesson.image?.url || lesson.image?.signedUrl,
  title: lesson.name || '',
  additionalInfo: lengthFromDurationSeconds(lesson.duration || 0),
  intensity: `RPE ${lesson.intensity}`,
  equipment: lesson.equipment,
  isSaved: !!lesson.isSaved,
  isCompleted: !!lesson.isCompleted,
});

export const Workouts: React.FC = () => (
  <GenericPage>
    <WorkoutListController allowMoreFilters />
  </GenericPage>
);

export default Workouts;
