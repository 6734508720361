const camelToSnake = (str: string): string => (
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
);

const snakeify = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => snakeify(item));
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => ({
      ...acc,
      [camelToSnake(key)]: snakeify(obj[key]),
    }), {});
  }

  return obj;
};

export default snakeify;
