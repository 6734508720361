import React from 'react';
import styled, { useTheme } from 'styled-components';

interface IconProps {
  color?: string;
}
export const RewindIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.white;
  const IconWrapper = styled.div`
    width: 1.5625rem;
    height: 1.5rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  `;
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
        <g clipPath="url(#clip0_4649_3304)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 12.5C0.5 8.51168 2.53029 4.99756 5.61372 2.93479L6.66293 4.63976C4.15026 6.34917 2.5 9.23186 2.5 12.5C2.5 17.7467 6.75329 22 12 22C17.2467 22 21.5 17.7467 21.5 12.5C21.5 7.42101 17.5143 3.27294 12.5 3.01293V4L11.5123 3.0123C11.5082 3.01251 11.5041 3.01272 11.5 3.01293V3L10.5 2L12.5 0V1.01067C18.6193 1.27245 23.5 6.31625 23.5 12.5C23.5 18.8513 18.3513 24 12 24C5.64873 24 0.5 18.8513 0.5 12.5Z"
            fill={colorToUse}
            fill-opacity="0.8"
          />
        </g>
        <defs>
          <clipPath id="clip0_4649_3304">
            <rect width="24" height="24" fill={colorToUse} transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};

export const ForwardIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.white;
  const IconWrapper = styled.div`
    width: 1.5625rem;
    height: 1.5rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  `;
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
        <g clipPath="url(#clip0_4649_3312)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5 12.5C23.5 8.51168 21.4697 4.99756 18.3863 2.93479L17.3371 4.63976C19.8497 6.34917 21.5 9.23186 21.5 12.5C21.5 17.7467 17.2467 22 12 22C6.75329 22 2.5 17.7467 2.5 12.5C2.5 7.42101 6.48572 3.27294 11.5 3.01293V4L12.4877 3.0123C12.4918 3.01251 12.4959 3.01272 12.5 3.01293V3L13.5 2L11.5 0V1.01067C5.38069 1.27245 0.5 6.31625 0.5 12.5C0.5 18.8513 5.64873 24 12 24C18.3513 24 23.5 18.8513 23.5 12.5Z"
            fill={colorToUse}
            fill-opacity="0.8"
          />
        </g>
        <defs>
          <clipPath id="clip0_4649_3312">
            <rect width="24" height="24" fill={colorToUse} transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};

export const PauseIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.white;

  const IconWrapper = styled.div`
    width: 4.5625rem;
    height: 4.5rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <IconWrapper>
      <svg viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1" width="70" height="70" rx="35" stroke={colorToUse} strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5 31H31.5V41H34.5V31ZM41.5 31H38.5V41H41.5V31Z"
          fill={colorToUse}
        />
      </svg>
    </IconWrapper>
  );
};

export const PlayIcon: React.FC<IconProps> = ({ color }) => {
  const theme = useTheme();
  const colorToUse = color || theme.colors.white;

  const IconWrapper = styled.div`
    width: 4.5625rem;
    height: 4.5rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <IconWrapper>
      <svg viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1" width="70" height="70" rx="35" stroke={colorToUse} strokeWidth="2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M41.5 36L31.5 42V30L41.5 36Z" fill={colorToUse} />
      </svg>
    </IconWrapper>
  );
};

export const PlayOnThumbnailIcon: React.FC<IconProps> = () => {
  const theme = useTheme();
  const IconWrapper = styled.div`
    width: 4.75rem;
    height: 4.6875rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <IconWrapper>
      <svg viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="76" height="75" fill={theme.colors.focoltone} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.1133 37.6777L30.4819 51.6863V23.6692L52.1133 37.6777Z"
          fill={theme.colors.black}
        />
      </svg>
    </IconWrapper>
  );
};
