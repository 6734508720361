import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { Equipment } from 'app/types/graphql';

import Button from 'app/ui/components/atoms/button';
import ClassCard from 'app/ui/components/atoms/class-card';
import Typography from 'app/ui/components/atoms/typography';

import Loading from 'app/ui/components/molecules/loading';
import EmptyState from 'app/ui/components/molecules/empty-state';
import { Pills, PillFieldSet } from 'app/ui/components/molecules/pill-field-set';

import GhostIcon from 'app/ui/components/atoms/ghost';
import FiltersIcon from 'app/ui/components/atoms/icons/filters';
import { routes } from 'app/routes/constants';

const WorkoutListContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { spacing } }) => `
    gap: ${spacing.jumbo};
  `}

  ${({ theme: { media, spacing } }) => `
    ${media.medium(
      () => `
        gap: ${spacing.massive};
      `,
    )}
  `}
`;

const WorkoutsTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;

  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        gap: 0;
        flex-direction: row;
        align-items: center;
      `,
    )}
  `}
`;

const WorkoutsTiles = styled.div`
  display: grid;
  align-self: center;
  
  ${({ theme: { spacing } }) => `
    gap: ${spacing.xlarge};
  `}

  ${({ theme: { media } }) => `
    ${media.small(
      () => `
        grid-template-columns: 1fr; // 1 item per row for mobile
      `,
    )}

    ${media.medium(
      () => `
        grid-template-columns: repeat(2, 1fr); // 2 items per row for tablets
      `,
    )} 


    ${media.large(
      () => `
        grid-template-columns: repeat(3, 1fr); // 3 items per row for large screens
      `,
    )}
  `}
`;

const WorkoutsFilter = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;

  div[class*='Fieldset'] {
    overflow-x: auto;
    flex-wrap: nowrap;
    margin: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ${({ theme: { media } }) => `
      ${media.medium(
        () => `
          overflow-x: none;
          flex-wrap: wrap;
        `,
      )}
    `}
  }
`;

const StyledButton = styled(Button)`
  align-self: stretch;

  ${({ theme: { media } }) => `
      ${media.medium(
        () => `
          align-self: unset;
        `,
      )}
    `}
`;

export type Workout = {
  id: string,
  srcSet: string,
  title: string,
  intensity: string,
  equipment?: Equipment[] | null,
  isSaved: boolean,
  isCompleted: boolean,
};

type WorkoutListWithDataProps = {
  workouts: Workout[],
  title?: string,
  largeTitle?: boolean,
  allowMoreFilters?: boolean,
  conceptFilters?: Pills,
  handleConceptChange?: (value: string) => void,
  setShowMoreFilters?: (value: boolean) => void,
  isLoading?: boolean,
};

const WorkoutList: React.FC<WorkoutListWithDataProps> = ({
  title,
  largeTitle,
  isLoading,
  workouts,
  allowMoreFilters = false,
  conceptFilters,
  handleConceptChange,
  setShowMoreFilters,
}) => {
  const intl = useIntl();

  if (isLoading) {
    return <Loading />;
  }

  const filterBtnText = intl.formatMessage({ id: 'workoutLibrary.buttons.filters' });

  const emptyStateTitle = intl.formatMessage({ id: 'filters.emptyState.title' });
  const emptyStateCopy = intl.formatMessage({ id: 'filters.emptyState.copy' });

  return (
    <WorkoutListContainer>
      <WorkoutsTitleWrapper>
        {title && (<Typography variant={largeTitle ? "h3" : "h4"}>{title}</Typography>)}
        { 
          allowMoreFilters && setShowMoreFilters && (
            <StyledButton
              type="secondary-white"
              label={filterBtnText}
              icon={<FiltersIcon color='white' />}
              iconPosition="left"
              onClick={() => setShowMoreFilters(true)}
            />
          )
        }
      </WorkoutsTitleWrapper>

      {conceptFilters && conceptFilters.length > 0 && handleConceptChange && (
        <WorkoutsFilter>
          <PillFieldSet 
            label=""
            pills={conceptFilters}
            onChange={(event) => handleConceptChange(event.target.value)}
          />
        </WorkoutsFilter>
      )}

      {workouts.length === 0 && (
        <EmptyState
          icon={<GhostIcon />}
          heading={emptyStateTitle}
          subheading={emptyStateCopy}
        />
      )}

      <WorkoutsTiles>
         {workouts.map((workout, index) => (
          <ClassCard
            lessonId={workout.id}
            key={`${workout.title}-${index}`}
            srcSet={workout.srcSet}
            title={workout.title}
            // TODO: navigate tidy
            to={routes.WORKOUT.replace(':id', workout.id)}
            intensity={workout.intensity}
            equipment={workout.equipment}
            isSaved={workout.isSaved}
            isCompleted={workout.isCompleted}
          />
        ))}
      </WorkoutsTiles>
    </WorkoutListContainer>
  );
};

export default WorkoutList;
