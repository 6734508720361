import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';
import { GroupContentItemData } from 'app/ui/components/molecules/content-item-areas-wrapper';

type GroupBreakdownProps = {
  group: GroupContentItemData;
  index: number;
  isSingleGroup?: boolean;
  isSmallVariant?: boolean;
  isOverviewMode?: boolean;
};

const GroupWrapper = styled.div<{ isSmallVariant?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.large};
  margin: ${({ theme: { spacing }, isSmallVariant }) => isSmallVariant ? '0' : `${spacing.medium} 0`};
`;

const ZoneColumn = styled.div<{ isSmallVariant?: boolean }>`
  align-items: flex-start;
  margin-right: ${({ theme, isSmallVariant }) => isSmallVariant ? 0 : theme.spacing.xsmall};
  min-width: 5rem;

  ${({ theme: { media }, isSmallVariant }) => `${media.medium(() => `
      min-width: ${isSmallVariant ? '5rem' : '7rem'};
    `)}
  `};
`;

const ZoneTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.bold};
`;

const ExercisesColumn = styled.div<{ isSmallVariant?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing }, isSmallVariant }) => isSmallVariant ? spacing.xsmall : spacing.medium};
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupNote = styled(Typography)`
  margin: 0;
`;

const Note = styled(Typography)`
  margin: ${({ theme }) => `${theme.spacing.xxsmall} 0`};
`;

const IndexNumber = styled.span`
  margin-right: ${({ theme }) => `${theme.spacing.medium}`};
  color: ${({ theme }) => `${theme.colors.focoltone}`};
`;

const GroupBreakdown: React.FC<GroupBreakdownProps> = ({
  group,
  index,
  isSingleGroup,
  isSmallVariant,
  isOverviewMode,
}) => {
  const theme = useTheme();

  const applySmallVariant = isSmallVariant || isOverviewMode;

  const textVariant = applySmallVariant ? 'whiteboard2' : 'whiteboard1';

  return (
    <GroupWrapper isSmallVariant={applySmallVariant}>
      {!isSingleGroup && (
        <ZoneColumn isSmallVariant={applySmallVariant}>
          <ZoneTitle variant={textVariant} color={theme.colors.focoltone}>
            ZONE {index}
          </ZoneTitle>
        </ZoneColumn>
      )}
      <ExercisesColumn isSmallVariant={applySmallVariant}>
        {!isOverviewMode && group.notes && (
          <GroupNote variant={textVariant} color={theme.colors.lightGray}>
            {group.notes}
          </GroupNote>
        )}
        {group.sectionExercises.map((exercise, i) => (
          <ExerciseWrapper key={`exercise-${index}-${i}`}>
            <Typography variant={textVariant} color={theme.colors.white}>
              {isSingleGroup && <IndexNumber>#{i+1}</IndexNumber>}
              {`${exercise.additionalInfo} ${exercise.title}`}
            </Typography>
            {!isOverviewMode && exercise.notes && (
              <Note variant={textVariant} color={theme.colors.lightGray}>
                {exercise.notes}
              </Note>
            )}
          </ExerciseWrapper>
        ))}
      </ExercisesColumn>
    </GroupWrapper>
  );
};

export default GroupBreakdown;
