import React from 'react';
import styled from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';

import SuspensionTrainer from 'app/ui/components/atoms/icons/equipment/suspension-trainer';
import Skierg from 'app/ui/components/atoms/icons/equipment/skierg';
import Kettlebell from 'app/ui/components/atoms/icons/equipment/kettlebell';
import Row from 'app/ui/components/atoms/icons/equipment/row';
import Dumbbell from 'app/ui/components/atoms/icons/equipment/dumbbell';
import Sled from 'app/ui/components/atoms/icons/equipment/sled';
import Wallball from 'app/ui/components/atoms/icons/equipment/wallball';
import Tread from 'app/ui/components/atoms/icons/equipment/tread';
import Sandbag from 'app/ui/components/atoms/icons/equipment/sandbag';
import Rope from 'app/ui/components/atoms/icons/equipment/rope';
import Spin from 'app/ui/components/atoms/icons/equipment/spin';
import Airbike from 'app/ui/components/atoms/icons/equipment/airbike';
import Bench from 'app/ui/components/atoms/icons/equipment/bench';
import BarbellPlate from 'app/ui/components/atoms/icons/equipment/barbell-plate';
import Medball from 'app/ui/components/atoms/icons/equipment/medball';

type GetEquipmentIconProps = {
  name: string,
  color: string,
}

type EquipmentIconWithNameProps = {
  name: string,
  color: string,
  icon: React.JSX.Element,
};

export type IconWithName = {
  icon: React.JSX.Element,
  name: string,
};

const EquipmentIconWithNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const EquipmentIconWithName = ({ icon, name, color }: EquipmentIconWithNameProps) => (
  <EquipmentIconWithNameWrapper>
    {icon}
    <Typography variant='h6' color={color}>{name}</Typography>
  </EquipmentIconWithNameWrapper>
);

export const getEquipmentIcon = ({ name, color }: GetEquipmentIconProps): React.JSX.Element | null => {
  switch (name) {
    case 'Assault Bike':
      return <Airbike color={color} />;
    case 'Barbell Plate':
      return <BarbellPlate color={color} />;
    case 'Bench':
      return <Bench color={color} />;
    case 'Bike Erg':
      return <Spin color={color} />;
    case 'Dumbbell':
      return <Dumbbell color={color} />;
    case 'Kettlebell':
      return <Kettlebell color={color} />;
    case 'Medball':
      return <Medball color={color} />;
    case 'Rower':
      return <Row color={color} />;
    case 'Sandbag':
      return <Sandbag color={color} />;
    case 'Ski Erg':
      return <Skierg color={color} />;
    case 'Sled':
      return <Sled color={color} />;
    case 'Sled Rope':
      return <Rope color={color} />;
    case 'Suspension Trainer':
      return <SuspensionTrainer color={color} />;
    case 'Treadmill':
      return <Tread color={color} />;
    case 'Wallball':
      return <Wallball color={color} />;
    default:
      return null;
  }
};

export const getEquipmentIconWithName = ({ name, color }: GetEquipmentIconProps): IconWithName | null => {
  const icon = getEquipmentIcon({ name, color });

  if (icon) {
    return { icon, name };
  }

  return null;
};
