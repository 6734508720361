import React from 'react';
import styled from 'styled-components';

import Loading from 'app/ui/components/atoms/loading';

const Wrapper = styled.div`
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingMolecule: React.FC = () => (
  <Wrapper>
    <Loading />
  </Wrapper>
);

export default LoadingMolecule;
